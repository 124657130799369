import * as React from 'react'
import {
  Dialog,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../../lib/ui/dialog'
import useExtendAlertExpirationForm from './useExtendExpirationForm'
import { Form } from '../../../lib/ui/form'
import { Button } from '../../../lib/ui/button'
export function ExpiresOnCell({ row }) {
  const alert = row.original

  const formatExpiresOn = new Date(alert.expires_at).toLocaleDateString()

  const subTitle = (
    <div className="line-clamp-1">
      <a className="cursor-pointer">{formatExpiresOn}</a>
    </div>
  )

  return (
    <div className="flex flex-col gap-1 pr-2">
      <div className="flex flex-row items-center gap-1 text-sm">
        <ExpiresOnDialog alert={alert}>
          <div className="flex flex-col">{subTitle}</div>
        </ExpiresOnDialog>
      </div>
    </div>
  )
}

const ExpiresOnDialog = ({ alert, children }) => {
  const today = new Date()
  const newExpiration = new Date(
    today.setMonth(today.getMonth() + 1),
  ).toLocaleDateString()
  const [showDialog, setShowAddDialog] = React.useState(false)
  const toggleShowDialog = () => {
    setShowAddDialog(!showDialog)
  }

  const { form, onSubmit, submitted } = useExtendAlertExpirationForm(
    alert,
    toggleShowDialog,
  )

  return (
    <Dialog open={showDialog} onOpenChange={toggleShowDialog}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Extend Alert Expiration</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="p-4">
              <div className="flex flex-col gap-4">
                <div>Extend alert expiration to {newExpiration}?</div>
                <div className="flex gap-2 self-end">
                  <Button disabled={submitted} type="submit">
                    Save
                  </Button>
                  <DialogClose asChild>
                    <Button variant="outline">Cancel</Button>
                  </DialogClose>
                </div>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
      <DialogClose />
    </Dialog>
  )
}
