import React from 'react'
import ReasonForLeavingSectionTwo from './ReasonForLeavingSectionTwo'

const ReasonForLeavingExercise = ({
  questions,
  handleChange,
  getResponse,
  exerciseIndex,
  conclusion,
  section,
  trackContent,
}) => {
  return (
    <div>
      {section === 0 && (
        <div>
          <div className="questions">
            {questions.map((question, index) => (
              <div
                key={index}
                className="mb-6 w-full font-roboto text-base font-medium"
              >
                <div className="flex w-full flex-col">
                  <label className="font-roboto-light !mb-1 text-sm font-medium">
                    {question.question}
                  </label>
                  <textarea
                    className="min-h-[125px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
                    onChange={(e) =>
                      handleChange(exerciseIndex, question.slug, e)
                    }
                    value={getResponse(exerciseIndex, question.slug) || ''}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-6 font-roboto text-lg">{conclusion}</div>
        </div>
      )}
      {section === 1 && <ReasonForLeavingSectionTwo data={trackContent} />}
    </div>
  )
}

export default ReasonForLeavingExercise
