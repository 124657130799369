import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useReducer,
} from 'react'

const ExerciseContext = createContext()

export const useExercise = () => {
  return useContext(ExerciseContext)
}

const initialState = {
  additionalIdeas: [],
}

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_IDEA': {
      const newIdeas = [
        ...state.additionalIdeas,
        { ...action.payload, completionDate: null },
      ]
      return {
        ...state,
        additionalIdeas: newIdeas,
      }
    }
    case 'DELETE_IDEA': {
      const filteredIdeas = state.additionalIdeas.filter(
        (_, index) => index !== action.payload,
      )
      return {
        ...state,
        additionalIdeas: filteredIdeas,
      }
    }
    case 'UPDATE_IDEA': {
      const updatedIdeas = state.additionalIdeas.map((idea, index) =>
        index === action.payload.index
          ? {
              ...idea,
              idea: action.payload.idea,
              completionDate: action.payload.completionDate,
            }
          : idea,
      )
      return {
        ...state,
        additionalIdeas: updatedIdeas,
      }
    }
    case 'UPDATE_COMPLETION_DATE': {
      const newIdeas = state.additionalIdeas.map((idea, index) => {
        if (index === action.payload.index) {
          return {
            ...idea,
            completionDate: action.payload.date,
          }
        }
        return idea
      })
      return {
        ...state,
        additionalIdeas: newIdeas,
      }
    }
    default:
      return state
  }
}
export const ExerciseProvider = ({
  exerciseId,
  exercisePartId,
  exerciseName,
  exerciseTime,
  content,
  bucketPath,
  candidateId,
  ssoName,
  candidateFirstName,
  dateAndTimeUpdated,
  module,
  children,
}) => {
  const [started, setStarted] = useState(
    (exercisePartId &&
      localStorage.getItem(`${exercisePartId}:started`) === 'true') ||
      false,
  )

  const [documentId, setDocumentId] = useState(exerciseId)
  const [documentPartId, setDocumentPartId] = useState(exercisePartId)
  const [trackContent, setTrackContent] = useState(content || {})
  const [exerciseIndex, setExerciseIndex] = useState(0)
  const formattedExerciseName = exerciseName.toLowerCase().split(' ').join('_')
  const [showConclusion, setShowConclusion] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (!showConclusion) {
      const handleReload = (e) => {
        if (started) {
          e.preventDefault()
          e.returnValue = ''
        }
      }
      window.addEventListener('beforeunload', handleReload)
      return () => {
        window.removeEventListener('beforeunload', handleReload)
      }
    }
  }, [started, showConclusion])

  useEffect(() => {
    if (exercisePartId) {
      localStorage.setItem(`${exercisePartId}:started`, started)
      localStorage.setItem(`${exercisePartId}:conclusion`, showConclusion)
    }
  }, [started, showConclusion, exercisePartId])

  const saveExercise = async (conclusion = false) => {
    const token = document.querySelector('meta[name="csrf-token"]').content
    const url = documentId
      ? `/documents/${documentId}/parts/${documentPartId}`
      : `/exercise/document/exercises?exercise_type=${formattedExerciseName}`

    try {
      if (!documentId) {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'X-CSRF-TOKEN': token,
          },
        })
        if (res.ok) {
          setStarted(true)
          const data = await res.json()
          setDocumentId(data.document_id)
          setDocumentPartId(data.document_part_id)
          return res
        } else {
          throw new Error('res not okay')
        }
      } else {
        const documentPartsInfo = {
          content: trackContent,
        }
        const res = await fetch(url, {
          method: 'PATCH',
          headers: {
            'X-CSRF-TOKEN': token,
            'Content-type': 'application/json',
          },
          body: JSON.stringify(documentPartsInfo),
        })
        if (res.ok) {
          if (conclusion) {
            setShowConclusion(true)
            document.body.classList.add('exercise-conclusion')
          } else if (
            exerciseIndex === 0 &&
            exerciseName === 'Reason For Leaving Statement'
          ) {
            // dont redirect
          } else {
            window.location.pathname = '/'
          }
          setReminders()
          return res
        } else {
          throw new Error('res not okay')
        }
      }
    } catch (error) {
      console.error('Error saving exercise:', error)
    }
  }

  const updateAdditionalIdeas = (response, completionDate) => {
    const validResponse = response.filter((res) => res !== '' && res !== null)

    const updatedIdeas = validResponse.map((res) => ({
      idea: res.idea || res,
      completionDate: res.completionDate || completionDate || null,
    }))

    return updatedIdeas
  }

  const updateResponse = (section, slug, response, completionDate) => {
    const validResponse = Array.isArray(response)
      ? response.filter(
          (res) =>
            res !== '' && res !== null && res.idea !== '' && res.idea !== null,
        )
      : response

    setTrackContent((prevContent) => {
      let updatedSection = { ...prevContent[section] }
      if (slug === 'additional_ideas') {
        const updatedIdeas = updateAdditionalIdeas(
          validResponse,
          completionDate,
        )
        updatedSection[slug] = updatedIdeas
      } else if (completionDate) {
        updatedSection[slug] = completionDate
      } else {
        updatedSection[slug] = validResponse
      }

      return {
        ...prevContent,
        [section]: updatedSection,
      }
    })
  }

  const getResponse = (section, slug) => {
    const response = trackContent[section]?.[slug]
    return response
  }
  const setReminders = async () => {
    const reminders = []

    Object.keys(trackContent).forEach((sectionKey) => {
      const section = trackContent[sectionKey]

      if (section.additional_ideas) {
        section.additional_ideas.forEach((idea) => {
          if (idea.completionDate) {
            reminders.push({
              content: idea.idea,
              due_date: idea.completionDate,
            })
          }
        })
      }

      Object.keys(section).forEach((key) => {
        if (
          key.startsWith('checkbox_') &&
          section[key] &&
          !isNaN(Date.parse(section[key]))
        ) {
          reminders.push({
            content: key,
            due_date: section[key],
          })
        }

        if (
          key.startsWith('action') &&
          section[key] &&
          section[key].date &&
          !isNaN(Date.parse(section[key].date))
        ) {
          reminders.push({
            content: section[key].action,
            due_date: section[key].date,
          })
        }
      })
    })

    try {
      const token = document.querySelector('meta[name="csrf-token"]').content
      const response = await fetch(
        `/candidates/${candidateId}/reminders/set_exercise_reminders`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': token,
          },
          body: JSON.stringify({ reminders, document_id: documentId }),
        },
      )

      if (!response.ok) {
        throw new Error('Failed to set reminders')
      }
    } catch (error) {
      console.error('Error setting reminders:', error)
    }
  }

  const handleBackClick = () => {
    if (exerciseIndex === 0) {
      setStarted(false)
    } else {
      setExerciseIndex((prev) => prev - 1)
    }
  }

  const requestAylaStatement = async (statementType) => {
    const token = document.querySelector('meta[name="csrf-token"]').content
    const url = `/exercise/create_ai_exit_statement?statement_type=${statementType}&document_part_id=${documentPartId}`

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
        },
      })

      if (!response.ok) {
        throw new Error('Ayla request was not ok')
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const value = {
    exercisePartId,
    documentId,
    setDocumentId,
    documentPartId,
    setDocumentPartId,
    exerciseName,
    exerciseTime,
    content,
    bucketPath,
    started,
    setStarted,
    showConclusion,
    setShowConclusion,
    exerciseIndex,
    setExerciseIndex,
    saveExercise,
    trackContent,
    setTrackContent,
    formattedExerciseName,
    updateResponse,
    getResponse,
    updateAdditionalIdeas,
    additionalIdeas: state.additionalIdeas,
    dispatch,
    ...state,
    handleBackClick,
    ssoName,
    candidateFirstName,
    dateAndTimeUpdated,
    module,
    requestAylaStatement,
  }

  return (
    <ExerciseContext.Provider value={value}>
      {children}
    </ExerciseContext.Provider>
  )
}
