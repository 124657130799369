import React from 'react'
import { useAlerts } from './AlertsProvider'
import DataTable from './DataTable'
import useTranslation from '../../shared/TranslationProvider'

const Page = () => {
  const { translations } = useAlerts()
  const { t } = useTranslation()
  const addAlert = t('Page.add_job_alert')
  const subheading = t('DataTable.subheading', { add_alert: addAlert })
  return (
    <div className="md:px-[2.5rem] md:py-[2.5rem]">
      <div className="flex min-h-[67vh] flex-col gap-4">
        <div className="">
          <h1>{translations.h1}</h1>
          <p>{subheading}</p>
        </div>
        <DataTable />
      </div>
    </div>
  )
}

export default Page
