import React, { useEffect, useState } from 'react'
import { useResume } from '../ResumeProvider'
import { ArrayInputs, EditorTextField } from './common'
import ExpandyPanel from './ExpandyPanel'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'

const SkillsItem = ({ index, skill, activeIndex, setActiveIndex }) => {
  const [name, setName] = useState(skill.name || '')
  const [keywords, setKeywords] = useState(skill.keywords || [])
  const [level, setLevel] = useState(skill.level || '')
  const { resumeDocument, setResumeDocument } = useResume()
  const [newKeyword, setNewKeyword] = useState(-1)
  const [headingLabel, setHeadingLabel] = useState(name || 'New Skill Section')

  const updateSkillItem = () => {
    const updatedSkills = resumeDocument.skills.map((item, i) => {
      if (i === index) {
        return { ...item, name, keywords, level }
      }
      return item
    })
    setHeadingLabel(name || 'New Skill Section')
    setResumeDocument({ ...resumeDocument, skills: updatedSkills })
  }

  const toggleExpanded = () => {
    if (activeIndex == index) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(index)
    }
  }

  const addKeyword = () => {
    setKeywords([...keywords, ''])
    setNewKeyword(keywords.length)
  }

  const onKeyPress = (e) => {
    if (e.target.value === '') return
    if (e.key === 'Enter') {
      const eventTargetIndex = parseInt(e.target.name.split('-')[2])
      if (eventTargetIndex < keywords.length - 1) {
        setNewKeyword(eventTargetIndex + 1)
      } else {
        addKeyword()
      }
    }
  }

  const deleteSkill = () => {
    const updatedSkills = resumeDocument.skills.filter((_, i) => i !== index)
    setResumeDocument({ ...resumeDocument, skills: updatedSkills })
  }

  const deleteKeyword = (keywordIndex) => {
    const updatedKeywords = keywords.filter((_, i) => i !== keywordIndex)
    setKeywords(updatedKeywords)

    const updatedSkills = resumeDocument.skills.map((item, i) => {
      if (i === index) {
        return { name, keywords: updatedKeywords, level }
      }
      return item
    })

    setResumeDocument({ ...resumeDocument, skills: updatedSkills })
  }

  useEffect(() => {
    if (newKeyword >= 0) {
      document
        .querySelector(
          `#skill-${index} input[name=keyword-arrayInput-${newKeyword}`,
        )
        ?.focus()
      setNewKeyword(-1)
    }
  }, [newKeyword, index])

  const expanded = activeIndex === index

  const commonProps = {
    index,
    onBlur: updateSkillItem,
    section: 'skills',
  }

  return (
    <div className="mb-4 flex flex-row items-center" id={`skill-${index}`}>
      <ExpandyPanel
        headingText={headingLabel}
        expanded={expanded}
        index={index}
        onClick={toggleExpanded}
        className="flex-grow"
      >
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorTextField
            label="Skill Group"
            value={name}
            updateFunc={setName}
            {...commonProps}
          />
          <EditorTextField
            label="Proficiency Level"
            value={level}
            updateFunc={setLevel}
            {...commonProps}
          />
        </div>
        <div>
          <div className="flex flex-row gap-2 pt-4">
            <span className="font-bold">Skills</span>
            <button
              className="flex flex-row items-center text-primary"
              onClick={addKeyword}
            >
              Add
              <PlusIcon className="ml-1 inline w-4 stroke-2" />
            </button>
          </div>
          <div className="flex flex-col gap-2 pt-4">
            <ArrayInputs
              items={keywords}
              keyPrefix="keyword"
              onChange={setKeywords}
              onBlur={updateSkillItem}
              onKeyPress={onKeyPress}
              onDeleteClick={deleteKeyword}
            />
          </div>
        </div>
      </ExpandyPanel>
      {!expanded && (
        <button
          onClick={deleteSkill}
          className="max-w-min text-[#b9b9b9]"
          aria-label="remove skill"
        >
          <TrashIcon className="ml-2 w-8" />
        </button>
      )}
    </div>
  )
}
export default SkillsItem
