import React from 'react'
import useTranslation, {
  TranslationProvider,
} from '../../shared/TranslationProvider'
import PersonalBrandProvider, {
  usePersonalBrand,
} from '../PersonalBrandProvider'
import translations from '../locales.json'
import CoverLettersRightNav from './CoverLettersRightNav'
import ExerciseList from '../ExerciseList'

const PersonalBrandCoverLettersInner = () => {
  const { t } = useTranslation('cover_letters')
  const { workshops } = usePersonalBrand()
  return (
    <div className="home-container flex h-full flex-col lg:col-span-9 lg:m-12">
      <div className="tw-enable min-h-[70vh] font-[Roboto] lg:flex lg:flex-row">
        <div className="flex-grow">
          <h1 className="mb-4 !font-roboto-slab text-2xl text-[32px] font-medium text-black">
            <span className="font-light">Create Your</span> Cover Letters
          </h1>
          <div className="mb-4">{t('cover_letters_intro_p1')}</div>
          <div className="mb-4">{t('cover_letters_intro_p2')}</div>
          <div className="mb-6">{t('cover_letters_intro_p3')}</div>
          <ExerciseList exercises={workshops} cardClassName="h-full" />
        </div>
      </div>
    </div>
  )
}

const PersonalBrandCoverLetters = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.PersonalBrand"
    >
      <PersonalBrandProvider {...props}>
        <div className="flex flex-col font-roboto text-cod-gray-800 lg:grid lg:grid-cols-12">
          <PersonalBrandCoverLettersInner />
          <CoverLettersRightNav />
        </div>
      </PersonalBrandProvider>
    </TranslationProvider>
  )
}

export default PersonalBrandCoverLetters
