import React from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../lib/ui/select'

const SetGoalForm = ({ setNewGoal }) => {
  const interestKeys = {
    new_job: 'Landing a similar job',
    career_change: 'Making a career change',
    contract_job: 'Finding a gig or contract job',
    working_for_self: 'Working for myself',
    retirement: 'Retirement',
  }

  return (
    <div className="flex flex-col gap-4 px-6 pt-2">
      <div>Please select your goal:</div>
      <Select onValueChange={(v) => setNewGoal(v)}>
        <SelectTrigger className="sm:w-4/6">
          <SelectValue placeholder="Select an option" />
        </SelectTrigger>
        <SelectContent>
          {Object.entries(interestKeys).map(([key, value]) => (
            <SelectItem key={key} value={key}>
              {value}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
export default SetGoalForm
