import React from 'react'
import { useExercise } from './ExerciseProvider'
import DesignThinkingExercise from './DesignThinkingExercise'
import MotivationReflectionExercise from './MotivationReflectionExercise'
import CareerBrainstormExercise from './CareerBrainstormExercise'
import { useIsDesktop } from '../../hooks/useMediaQuery'
import { ExerciseIcon } from './icons'
import ReasonForLeavingExercise from './ReasonForLeavingExercise'
import parse from 'html-react-parser'

const Layout = ({ pageInfo, section }) => {
  const {
    updateResponse,
    getResponse,
    exerciseName,
    exerciseIndex,
    trackContent,
  } = useExercise()
  const description = pageInfo.description
  const description2 = pageInfo.description_2
  const conclusion = pageInfo.conclusion
  const questions = Object?.values(pageInfo.questions ?? {})
  const title = pageInfo.title
  const isDesktop = useIsDesktop()

  const handleChange = (section, slug, event) => {
    const response =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    updateResponse(section, slug, response)
  }

  const handleDivClick = (section, slug) => {
    const currentValue = getResponse(section, slug) || false
    updateResponse(section, slug, !currentValue)
  }

  return (
    <div className="pb-8">
      <div className="grid grid-cols-10">
        <div
          className={`flex flex-col ${
            isDesktop ? 'col-span-9' : 'col-span-10'
          }`}
        >
          <h1 className="mb-4 font-roboto-slab text-3xl font-bold">{title}</h1>
          {description && (
            <p
              className={`font-roboto text-lg ${
                description2 ? '!mb-1' : '!mb-6'
              }`}
            >
              {parse(description)}
            </p>
          )}
        </div>
        {isDesktop && (
          <ExerciseIcon source="ExerciseSteps" className="ml-auto" />
        )}
      </div>
      {description2 && (
        <p className="!mb-6 font-roboto text-lg">{description2}</p>
      )}
      {exerciseName === 'Design Thinking' && (
        <DesignThinkingExercise
          section={section}
          questions={questions}
          handleDivClick={handleDivClick}
          handleChange={handleChange}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          trackContent={trackContent}
          conclusion={conclusion}
        />
      )}
      {exerciseName === 'Motivation Reflection' && (
        <MotivationReflectionExercise
          questions={questions}
          handleDivClick={handleDivClick}
          handleChange={handleChange}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          conclusion={conclusion}
          section={section}
          trackContent={trackContent}
        />
      )}
      {exerciseName === 'Career Brainstorm' && (
        <CareerBrainstormExercise
          questions={questions}
          handleDivClick={handleDivClick}
          handleChange={handleChange}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          conclusion={conclusion}
          section={section}
          trackContent={trackContent}
        />
      )}
      {exerciseName === 'Reason For Leaving Statement' && (
        <ReasonForLeavingExercise
          questions={questions}
          handleDivClick={handleDivClick}
          handleChange={handleChange}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          conclusion={conclusion}
          section={section}
          trackContent={trackContent}
        />
      )}
    </div>
  )
}

export default Layout
