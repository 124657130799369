import React, { useState } from 'react'
import { useResume } from '../ResumeProvider'
import { EditorHeading, newKey } from './common'
import { Button } from '../../../lib/ui/button'
import { PlusIcon } from '@heroicons/react/24/outline'
import SkillsItem from './SkillsItem'

export const SkillsEditor = () => {
  const { resumeDocument, setResumeDocument } = useResume()
  const skills = resumeDocument.skills || []
  const [activeIndex, setActiveIndex] = useState(-1)

  const addSkill = () => {
    const updatedSkills = [...skills, {}]
    setActiveIndex(updatedSkills.length - 1)
    setResumeDocument({ ...resumeDocument, skills: updatedSkills })
  }

  return (
    <div className="flex flex-col">
      <EditorHeading>Skills</EditorHeading>
      <div className="pb-4 tracking-[0.024px]">
        Select skills that show you are a great match for the role. Make sure
        they match the skills mentioned in the job listing.
      </div>
      {skills.map((skill, skillIndex) => {
        skill.key = skill.key || newKey()
        return (
          <SkillsItem
            index={skillIndex}
            key={skill.key}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            skill={skill}
          />
        )
      })}
      <Button onClick={addSkill} className="mt-4 max-w-min">
        Add Skill
        <PlusIcon className="ml-2 w-5" />
      </Button>
    </div>
  )
}
