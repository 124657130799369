import React from 'react'
import { cn } from '../../lib/utils'
import { ExerciseIcon, BuilderIcon, ShortCourseIcon, ResumeIcon } from './icons'
import { usePersonalBrand } from './PersonalBrandProvider'
import parse from 'html-react-parser'

const ExerciseCard = ({ exercise, className, cardClassName }) => {
  const {
    actionBlockImageUrl,
    headerImageAlt,
    path,
    path2,
    title,
    description,
    cta,
    cta2,
    type,
  } = exercise

  const { uploadPath } = usePersonalBrand()

  return (
    <div
      className={cn(
        'grid grid-cols-1 gap-4 rounded-3xl bg-gradient-reminders pl-4 lg:grid-cols-2 xl:grid-cols-3',
        className,
      )}
    >
      <div className="self-center p-4 md:col-span-2">
        {type === 'builder' ? (
          <BuilderIcon className="mb-2 h-10 w-10 stroke-primary stroke-2" />
        ) : type === 'resume-builder' ? (
          <ResumeIcon className="mb-2 h-10 w-10 stroke-primary stroke-2" />
        ) : type === 'workshop' ? (
          <ShortCourseIcon className="mb-2 h-10 w-10 stroke-primary stroke-2" />
        ) : (
          <ExerciseIcon className="mb-2 h-10 w-10 stroke-primary stroke-2" />
        )}
        <h2
          role="heading"
          tabIndex="0"
          aria-level="2"
          className="!mb-4 !mt-0 font-roboto-slab text-xl font-bold text-primary focus:overflow-visible"
          title={title}
        >
          {title}
        </h2>
        <p className="!mb-4 line-clamp-3 font-roboto-slab text-primary">
          {description}
        </p>
        <div className="flex flex-row gap-4 self-center">
          {cta2 ? (
            <>
              <a
                href={path}
                className="flex items-center justify-center rounded-3xl bg-primary px-4 py-3 text-base font-medium text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white sm:py-2"
              >
                {cta}
              </a>
              {parse(uploadPath)}
            </>
          ) : (
            <a
              href={path}
              className="flex items-center justify-center rounded-3xl bg-primary px-4 py-3 text-base font-medium text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white sm:py-2"
            >
              {cta}
            </a>
          )}
        </div>
      </div>
      <div className="h-full overflow-hidden max-lg:hidden md:rounded-r-[20px] lg:hidden xl:inline">
        <img
          src={actionBlockImageUrl}
          alt={headerImageAlt}
          className={cn(
            'aspect-[8/5] h-full w-full object-cover',
            cardClassName,
          )}
        />
      </div>
    </div>
  )
}

export default ExerciseCard
