import * as React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { CriteriaCell } from './CriteriaCell'
import { FrequencyCell } from './FrequencyCell'
import { ExpiresOnCell } from './ExpiresOnCell'
import { ContactMethodCell } from './ContactMethodCell'
import { ViewJobsCell } from './ViewJobsCell'
import { EditAlertCell } from './EditAlertCell'
import { DeleteAlertCell } from './DeleteAlertCell'

export const columns = [
  {
    accessorKey: 'criteria',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <CriteriaCell {...props} />,
    enableSorting: false,
  },
  {
    accessorKey: 'frequency',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <FrequencyCell {...props} />,
    sortUndefined: 'last',
  },
  {
    accessorKey: 'contact_method',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <ContactMethodCell {...props} />,
    sortUndefined: 'last',
  },
  {
    accessorKey: 'expires_at',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <ExpiresOnCell {...props} />,
    sortUndefined: 'last',
    sortDescFirst: true,
  },

  {
    accessorKey: 'view-jobs',
    header: (props) => <BlankHeader {...props} />,
    cell: (props) => <ViewJobsCell {...props} />,
    enableSorting: false,
  },
  {
    accessorKey: 'edit',
    header: (props) => <BlankHeader {...props} />,
    cell: (props) => <EditAlertCell {...props} />,
    enableSorting: false,
  },
  {
    accessorKey: 'delete',
    header: (props) => <BlankHeader {...props} />,
    cell: (props) => <DeleteAlertCell {...props} />,
    enableSorting: false,
  },
]

function TableHeader({ column }) {
  const { t } = useTranslation('TableHeader')

  return <div className="font-roboto">{t(column.id)}</div>
}

function BlankHeader() {
  return <div className="font-roboto"></div>
}
