import React from 'react'
import ExerciseCard from './ExerciseCard'

const ExerciseList = ({ exercises, cardClassName }) => {
  return (
    <>
      <div className="grid grid-cols-1 gap-x-12 gap-y-6 max-lg:m-8">
        {exercises.map((exercise, idx) => (
          <ExerciseCard
            key={`exercise-${idx}`}
            exercise={exercise}
            className={'col-span-2 md:col-span-1'}
            cardClassName={cardClassName}
          />
        ))}
      </div>
    </>
  )
}

export default ExerciseList
