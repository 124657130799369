import React, { createContext, useContext, useState } from 'react'
import { apiDelete } from '../../../util/api'

const SavedRecruitersContext = createContext()

const useSavedRecruiters = () => {
  return useContext(SavedRecruitersContext)
}

export default useSavedRecruiters

export const SavedRecruitersProvider = ({
  savedRecruiters,
  candidateId,
  children,
}) => {
  const [recruiterList, setRecruiterList] = useState(savedRecruiters)

  const deleteRecruiter = async (row) => {
    let response
    try {
      response = await apiDelete(
        `/job-search/recruiters/my_recruiters/${row?.original?.id}`,
      )
    } catch (error) {
      console.log(error)
    }
    if (response.status === 200) {
      setRecruiterList((prev) => prev.filter((ele) => ele !== row.original))
    } else {
      throw new Error('Error deleting item')
    }
  }

  const value = {
    recruiterList,
    setRecruiterList,
    candidateId,
    deleteRecruiter,
  }

  return (
    <SavedRecruitersContext.Provider value={value}>
      {children}
    </SavedRecruitersContext.Provider>
  )
}
