import * as React from 'react'
import { useAlerts } from './AlertsProvider'
import useTranslation from '../../shared/TranslationProvider'
import { TrashIcon } from './icons'
import { Button } from '../../../lib/ui/button'

export function DeleteAlertCell({ row }) {
  const { deleteAlert } = useAlerts()
  const alert = row.original
  const { id } = alert
  const { t } = useTranslation('DataTable')

  const handleDeleteAlert = async (e) => {
    e.preventDefault()
    window.confirm(t('delete_alert_confirm')) && deleteAlert(id)
  }

  return (
    <Button
      onClick={handleDeleteAlert}
      className="bg-transparent hover:bg-transparent"
      title={t('delete_alert')}
    >
      <TrashIcon
        className={
          'bg-transparent text-red-500 hover:bg-transparent hover:text-red-400'
        }
      />
    </Button>
  )
}
