import * as React from 'react'
import { OpenQuoteIcon, CloseQuoteIcon, IntooIcon } from '../Icons'
import { cn } from '../../../lib/utils'

export function QuoteCard({ quote, padding, className }) {
  return (
    <>
      <style>
        {`.quote-gradient {
			background: linear-gradient(90deg, #1C6F29 0%, #75B21D 93%);
    	  }`}
      </style>
      <div
        className={cn(
          'quote-gradient relative w-[100%] overflow-hidden rounded-[20px] border border-[#B1B1B1] p-4 shadow-md md:p-6',
          className,
        )}
      >
        <div className={cn('flex gap-4 md:pr-[125px]', padding)}>
          <div className="flex flex-col items-center">
            <OpenQuoteIcon className="mt-[-5px]" />
          </div>
          <div className="font-roboto-slab text-2xl text-white">
            {quote['text']} - {quote['author']}
          </div>
          <div className="flex flex-col-reverse">
            <CloseQuoteIcon className="mt-[5px]" />
          </div>
        </div>
        <IntooIcon className="absolute right-[-80px] top-[-50px]" />
      </div>
    </>
  )
}
