import React, { useState } from 'react'
import { useResume } from '../ResumeProvider'
import { Button } from '../../../lib/ui/button'
import { PlusIcon } from '@heroicons/react/24/outline'
import { EditorHeading, newKey } from './common'
import EducationItem from './EducationItem'

const EducationEditor = () => {
  const { resumeDocument, setResumeDocument } = useResume()
  const education = resumeDocument?.education || []
  const [activeIndex, setActiveIndex] = useState(-1)

  const addEducation = () => {
    const newIndex = education.length
    const updatedEducation = [...education, {}]
    setActiveIndex(newIndex)

    setResumeDocument({ ...resumeDocument, education: updatedEducation })
  }

  return (
    <div className="flex flex-col">
      <EditorHeading>Education</EditorHeading>
      <div className="pb-4 tracking-[0.024px]">
        Show your relevant work experience, show accomplishments, and if
        possible, use numbers/facts.
      </div>
      {education.map((edu, index) => {
        edu.key = edu.key || newKey()
        return (
          <EducationItem
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            index={index}
            key={edu.key}
            education={edu}
          />
        )
      })}
      <Button onClick={addEducation} className="max-w-min">
        Add Education
        <PlusIcon className="ml-2 w-5" />
      </Button>
    </div>
  )
}
export default EducationEditor
