import * as React from 'react'
import { EmailIcon, MobileIcon } from './icons'
import useTranslation from '../../shared/TranslationProvider'

export function ContactMethodCell({ row }) {
  const alert = row.original
  const { t } = useTranslation()

  const { via_email, via_mobile } = alert

  return (
    <div className="flex flex-col gap-1 pr-2">
      <div className="flex flex-row items-center gap-1 text-sm">
        <div className="flex items-center justify-center">
          {via_mobile && (
            <div title={t('NewJobAlert.Form.sms')}>
              <MobileIcon />
            </div>
          )}
          {via_email && (
            <div title={t('NewJobAlert.Form.email')}>
              {' '}
              <EmailIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
