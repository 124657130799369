import React from 'react'
import { Button } from '../../lib/ui/button'
import { cn } from '../../lib/utils'
import { useIsDesktop } from '../../hooks/useMediaQuery'
import { useTranslation } from '../../hooks/useTranslation'

const buttonClasses =
  'rounded-full flex justify-center items-center gap-2 py-0 px-12'
const buttonWidthClasses = 'w-[120px] sm:w-[172px] h-12 sm:h-10'
const primaryButtonClasses =
  'bg-primary-button-cd hover:bg-primary-button-cd-darker-75'
const backButtonClasses = 'border-[#5A5A5A] bg-[#fff] hover:bg-[#F0F0F0]'

export const PrimaryExerciseButton = ({ className, children, ...props }) => (
  <Button
    {...props}
    className={cn(
      buttonClasses,
      buttonWidthClasses,
      className,
      primaryButtonClasses,
    )}
  >
    {children}
  </Button>
)

export const BackExerciseButton = ({ className, children, ...props }) => (
  <Button
    {...props}
    variant="outline"
    className={cn(
      buttonClasses,
      buttonWidthClasses,
      className,
      backButtonClasses,
    )}
  >
    {children}
  </Button>
)

const CommonButton = ({
  onClick,
  className,
  containerClassName,
  text,
  disabled,
  children,
}) => {
  const isDesktop = useIsDesktop()

  const sizeClasses = (size) => {
    switch (size) {
      case 'sm':
        return 'h-9 px-3 text-sm'
      case 'lg':
        return 'h-12 px-12 text-lg'
      case 'xl':
        return 'h-[3.75rem] px-16 text-xl'
      case 'icon':
        return 'h-10 w-10'
      default:
        return 'h-10 px-8 py-2'
    }
  }

  const screenSize = isDesktop ? 'lg' : 'sm'

  return (
    <div className={`mt-8 ${!isDesktop ? 'mb-5' : ''} ${containerClassName}`}>
      <Button
        onClick={onClick}
        className={`op-btn inline-flex items-center justify-center gap-4 whitespace-nowrap rounded-full font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ${className} ${sizeClasses(
          screenSize,
        )}`}
        disabled={disabled}
      >
        {children || text}
      </Button>
    </div>
  )
}

export const StartExerciseButton = ({
  className,
  containerClassName,
  children,
  ...props
}) => (
  <CommonButton
    {...props}
    className={cn(className)}
    containerClassName={containerClassName}
  >
    {children}
  </CommonButton>
)

export const ReturnToResourceCenter = () => {
  const { t } = useTranslation('exercise')
  const handleClick = () => {
    const path = window.location.pathname.split('/')[1]
    const newUrl = `/${path}/resources`
    window.location.replace(newUrl)
  }

  return (
    <CommonButton
      onClick={handleClick}
      text={t('return_to_resource_center')}
      containerClassName="flex flex-col items-center gap-6"
    />
  )
}

export const DownloadExerciseButton = ({ href, text }) => {
  const handleClick = () => window.open(href, '_blank', 'noopener,noreferrer')

  return <CommonButton onClick={handleClick} text={text} />
}

export const AylaButton = ({ children, onClick, className, disabled }) => (
  <CommonButton onClick={onClick} className={cn(className)} disabled={disabled}>
    {children}
  </CommonButton>
)
