import * as React from 'react'
import { useAlerts } from './AlertsProvider'
import useTranslation from '../../shared/TranslationProvider'

export function ViewJobsCell({ row }) {
  const { fetchAlertJobCount, createTransitionNotification } = useAlerts()
  const [numJobs, setNumJobs] = React.useState()
  const alert = row.original
  const { what, where } = alert
  const { t } = useTranslation('DataTable')

  React.useEffect(() => {
    const getJobCount = () => {
      fetchAlertJobCount(alert)
        .then((res) => {
          setNumJobs(res.data.job_count)
          return res
        })
        .catch((err) => {
          console.log(err)
          return err
        })
    }
    getJobCount()
  }, [alert, fetchAlertJobCount])

  const viewJobsText = numJobs
    ? numJobs === 1
      ? t('view_job')
      : t('view_num_jobs', { numJobs })
    : t('view_jobs')

  const getParams = () => {
    if (what && where) {
      return `what=${what}&where=${where}`
    } else if (what) {
      return `what=${what}`
    } else if (where) {
      return `where=${where}`
    }
  }
  const query = getParams()

  const url = query ? `/job-search/jobs?${query}` : `/job-search/jobs`

  return (
    <a
      onClick={() => createTransitionNotification('viewedResults')}
      title="view jobs"
      href={url}
    >
      {viewJobsText}
    </a>
  )
}
