import React, { useState } from 'react'
import { cn } from '../../lib/utils'
import SetGoalDialog from './SetGoalDialog'
import SetTimelineDialog from './SetTimelineDialog'
import { TargetIcon, TimeIcon } from './TrackTileComponents/icons'

const GoalTile = ({
  className = null,
  variant = 'goal',
  goal,
  timeline,
  getUserTrackerSections,
}) => {
  const [showGoalDialog, setShowGoalDialog] = useState(false)
  const [showDateDialog, setShowDateDialog] = useState(false)
  const toggleGoalDialog = () => setShowGoalDialog((prev) => !prev)
  const toggleDateDialog = () => setShowDateDialog((prev) => !prev)
  const headerClasses = cn(
    'h-full w-[223px] items-center text-sm font-medium uppercase leading-5 tracking-wide text-intoo-blue-medium bg-[#E0F2F9] rounded-lg p-4',
    className,
  )

  return (
    <div>
      <SetGoalDialog
        showDialog={showGoalDialog}
        toggleDialog={toggleGoalDialog}
        goal={goal}
        getUserTrackerSections={getUserTrackerSections}
      />
      <SetTimelineDialog
        showDialog={showDateDialog}
        toggleDialog={toggleDateDialog}
        timeline={timeline}
        getUserTrackerSections={getUserTrackerSections}
      />
      {variant === 'goal' && (
        <div className={headerClasses}>
          <div className="mb-4 flex items-center gap-2">
            <TargetIcon variant="primary" />
            <div>YOUR GOAL:</div>
          </div>
          <div
            className="cursor-pointer underline"
            onClick={toggleGoalDialog}
            id="tile_goal"
          >
            {goal}
          </div>
        </div>
      )}
      {variant === 'timeline' && (
        <div className={headerClasses}>
          <div className="mb-4 flex items-center gap-2">
            <TimeIcon variant="primary" height="32" />
            <div>YOUR TIMELINE:</div>
          </div>
          <div
            className="cursor-pointer underline"
            onClick={toggleDateDialog}
            id="tile_timeline"
          >
            {goal}
          </div>
        </div>
      )}
    </div>
  )
}
export default GoalTile
