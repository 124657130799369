import React, { useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from '@tanstack/react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../lib/ui/table'
import useTranslation from '../../shared/TranslationProvider'
import { useAlerts } from './AlertsProvider'
import { columns } from './columns'
import NoSavedAlertsImage from './NoSavedAlertsImage'
import { cn } from '../../../lib/utils'
import { SortIcon } from './icons'
import TablePagination from './TablePagination'

const blankColumns = ['view-jobs', 'edit', 'delete']

const DataTable = () => {
  const { alerts } = useAlerts()
  const [sorting, setSorting] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const table = useReactTable({
    debugTable: true,
    data: alerts,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
    state: {
      pagination,
      sorting,
    },
  })
  const { t } = useTranslation()
  const tableHeaderClasses = cn(
    'job-search-table-header h-6 pl-0 text-sm font-bold text-foreground data-[col-id=status]:w-[96px] sm:h-12 sm:px-4 data-[col-id=status]:sm:w-[120px]',
  )

  const showPagination = alerts.length > 10

  const totalPages = table.getPageCount()

  return (
    <div className="flex flex-col gap-4">
      <div className="self-end">
        <a title={t('Page.add_job_alert')} href="/job-search/alerts/new">
          + {t('Page.add_job_alert')}
        </a>
      </div>
      <div className="rounded-2xl border border-alto-200">
        <Table tableClass="rounded-2xl">
          <TableHeader className="rounded-2xl bg-intoo-blue-medium">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={`${headerGroup.id}-header_group`}
                className="border-alto-200 bg-intoo-blue-medium p-4 text-white  hover:bg-intoo-blue-medium sm:px-8 sm:py-6"
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={`${header.id}-header`}
                      className={tableHeaderClasses}
                      data-col-id={header.column.id}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={cn(
                            'flex items-center gap-2',
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                          )}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? t('Page.sort_ascending')
                                : header.column.getNextSortingOrder() === 'desc'
                                  ? t('Page.sort_descending')
                                  : t('Page.clear_sort')
                              : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}{' '}
                          {header.column.getCanSort() && <SortIcon />}
                        </div>
                      )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table
                .getRowModel()
                .rows.map((row) => (
                  <DataTableRow key={`${row.id}-table_row`} row={row} />
                ))
            ) : (
              <EmptyDataTable />
            )}
          </TableBody>
        </Table>
      </div>
      {showPagination && (
        <TablePagination table={table} totalPages={totalPages} />
      )}
    </div>
  )
}

export default DataTable

const EmptyDataTable = () => {
  const { t } = useTranslation()
  return (
    <TableRow>
      <TableCell colSpan={columns}>
        <div className="flex flex-col items-center justify-center">
          <NoSavedAlertsImage />
          <div className="max-w-96 text-center">
            <h2 className="p-8">{t('DataTable.no_saved_alerts_title')}</h2>
            <p className="text-lg">{t('DataTable.no_saved_alerts_message')}</p>
            <p className="text-lg">
              <span>{t('DataTable.get_started')}, </span>
              <span>
                <a href="/job-search/jobs">{t('DataTable.explore_jobs')}</a>
              </span>
            </p>
          </div>
        </div>
      </TableCell>
    </TableRow>
  )
}

const DataTableRow = ({ row, ...props }) => {
  return (
    <>
      <TableRow
        data-state={row.getIsSelected() && 'selected'}
        fred={5}
        className={
          'border-alto-200 hover:bg-transparent data-[last-updated=true]:bg-alto-50'
        }
        {...props}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell
            key={`${row.id}_${cell.id}`}
            className="pb-2 pl-0 pr-0 pt-3 sm:p-4 sm:pr-1"
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
      <TableRow className="border-b-0 p-0">
        <TableCell colSpan={columns.length} className="border-b-0 p-0">
          <div className="invisible max-h-0 overflow-hidden bg-[#f7f7f7] transition-all duration-500"></div>
        </TableCell>
      </TableRow>
    </>
  )
}
