import React from 'react'
import { Button } from '../../../lib/ui/button'
import { cn } from '../../../lib/utils'
import useTranslation from '../../shared/TranslationProvider'

const TablePagination = ({ table, totalPages }) => {
  const { t } = useTranslation('Page')
  const currentPage = table.getState().pagination.pageIndex + 1
  const arrLength = totalPages > 3 ? 3 : totalPages
  const getPagesArr = () => {
    const pages = []
    const startingPage = currentPage === 1 ? 1 : currentPage - 1
    for (let i = startingPage; i <= arrLength; i++) {
      if (i <= totalPages) pages.push(i)
    }
    return pages
  }

  const pageArr = getPagesArr()

  const buttonClasses = cn('rounded-[5px] p-4')
  return (
    <div className="flex justify-center gap-4">
      <PaginationButton
        className={buttonClasses}
        onClick={() => table.firstPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<<'}
      </PaginationButton>
      <div className="flex justify-center gap-2">
        {pageArr.map((page, pageIdx) => (
          <PaginationButton
            key={`pagination-${currentPage}-${pageIdx}`}
            className={buttonClasses}
            onClick={() => table.setPageIndex(page - 1)}
            active={currentPage === page}
          >
            {page}
          </PaginationButton>
        ))}
      </div>
      <PaginationButton
        className={buttonClasses}
        onClick={() => table.lastPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>>'}
      </PaginationButton>
      <div className="flex items-center gap-1">
        <select
          className={'rounded-[5px]'}
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        {t('alerts_per_page')}
      </div>
    </div>
  )
}

export default TablePagination

const PaginationButton = ({ active, className, children, ...props }) => {
  if (active)
    return (
      <Button className={cn('cursor-default', className)}>{children}</Button>
    )
  return (
    <Button className={className} variant={'outline'} {...props}>
      {children}
    </Button>
  )
}
