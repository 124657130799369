import React from 'react'
import BasicsEditor from './BasicsEditor'
import EmploymentEditor from './EmploymentEditor'
import EducationEditor from './EducationEditor'
import { SkillsEditor } from './SkillsEditor'

const Editor = () => {
  return (
    <div className="grid grid-cols-1 gap-14">
      <BasicsEditor />
      <EmploymentEditor />
      <EducationEditor />
      <SkillsEditor />
      <div className="h-80"></div>
    </div>
  )
}
export default Editor
