import React from 'react'
import { FavoriteIcon, ArticlesIcon, ClockIcon, CheckMarkIcon } from './icons'
import { useResources } from './ResourcesProvider'
import { useTranslation } from '../../hooks/useTranslation'
import { cn } from '../../lib/utils'

const ArticleCard = ({ article }) => {
  const {
    headerImageUrl,
    headerImageAlt,
    title,
    description,
    length,
    hasAchievement,
  } = article
  const { checkFavorite, toggleFavorite, getArticlePath, isRecentlyAdded } =
    useResources()
  const articlePath = getArticlePath(article)
  const { t } = useTranslation('index')

  const ctaClasses = hasAchievement
    ? 'bg-white flex border text-[#2F2F2F] hover:!bg-accent focus:bg-accent focus:border-accent focus:text-[#2F2F2F] active:bg-accent active:border-accent hover:text-[#2F2F2F] border-[#2F2F2F] active:text-[#2F2F2F]'
    : 'bg-primary text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white'

  const ctaText = hasAchievement ? 'Again' : 'Article'

  const isFavorite = checkFavorite(article)
  const favoriteIconClass = isFavorite
    ? 'fill-primary stroke-primary'
    : 'stroke-primary'
  const favoriteTitle = isFavorite
    ? t('bookmarked')
    : t('bookmark_this_resource')

  const recentlyAdded = isRecentlyAdded(article)

  return (
    <div className="relative overflow-hidden rounded-3xl border border-solid border-[#B9B9B9]">
      {recentlyAdded && (
        <div
          className="absolute left-0 top-0 z-10 rounded-br-3xl px-7 py-2 text-sm font-bold text-white"
          style={{
            background: 'linear-gradient(90deg, #42A7FF 0%, #76C0FF 100%)',
          }}
        >
          Recently Added
        </div>
      )}
      <div className="absolute right-4 top-4">
        <button
          className="rounded-lg bg-white p-2 hover:bg-gray-100"
          title={favoriteTitle}
          onClick={() => toggleFavorite(article)}
        >
          <FavoriteIcon
            className={favoriteIconClass}
            title={favoriteTitle}
            height={24}
          />
        </button>
      </div>
      <div className="absolute bottom-52 right-4">
        <div className="rounded-lg !border-2 border-solid !border-primary bg-white p-2 text-primary">
          <span className="flex flex-row items-center justify-center px-4 text-center">
            {length}
            <ClockIcon className="stroke-primary pl-2" height={24}></ClockIcon>
          </span>
        </div>
      </div>
      <img
        src={headerImageUrl}
        alt={headerImageAlt}
        className="aspect-[22/11] w-full object-cover object-center"
      />
      <div className="flex flex-col p-4 md:justify-between">
        <div className="h-28">
          <p className="!my-0 mb-8 line-clamp-4 flex-grow overflow-hidden md:leading-relaxed">
            <span
              tabIndex="0"
              aria-level="2"
              className="!mb-2 !mt-0 break-normal font-roboto text-lg focus:overflow-visible"
              title={title}
            >
              {title}
            </span>
            <br />
            {description}
          </p>
        </div>
        <div className="mt-4">
          <a
            href={articlePath}
            className={cn(
              'flex w-full items-center justify-center rounded-3xl px-4 py-3 text-base font-medium sm:py-2',
              ctaClasses,
            )}
          >
            Read {ctaText}
            <CtaIcon hasAchievement={hasAchievement} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard

const CtaIcon = ({ hasAchievement }) => {
  if (hasAchievement) return <CheckMarkIcon className="ml-2 stroke-[#2F2F2F]" />
  return <ArticlesIcon className="ml-2 stroke-white" />
}
