import * as React from 'react'

import { useTranslation } from '../../../hooks/useTranslation'
import { useRecruiterSearch } from './RecruiterSearchProvider'
import { HeartOutlineIcon, HeartSolidIcon } from './Icons'
import { cn } from '../../../lib/utils'

export default function RecruiterCard({ recruiter }) {
  const { t } = useTranslation('RecruiterCard')
  const {
    selectedRecruiter,
    handleSelectRecruiter,
    isSavedRecruiter,
    addToMyRecruiters,
    removeFromMyRecruiters,
    isSavedRecruiterCompany,
  } = useRecruiterSearch()
  const selected = selectedRecruiter && selectedRecruiter?.id === recruiter?.id

  const description = recruiter.description
    ? recruiter.description
        .replace(/<[^>]*>?/gm, ' ')
        .replace(/\|\|\|/g, '')
        .replace(/\|\|/g, '')
        .split(' ')
        .filter((word) => word.length <= 40)
        .join(' ')
    : ''

  const toggleRecruiter = (recruiter) => {
    if (isSavedRecruiter(recruiter)) {
      removeFromMyRecruiters(recruiter)
    } else addToMyRecruiters(recruiter)
  }

  return (
    <li
      className="relative flex cursor-pointer flex-row gap-4 rounded-2xl border-2 p-4 transition-colors hover:border-gray-400 hover:shadow-lg data-[selected=true]:border-intoo-blue-bright"
      data-selected={selected}
      onClick={handleSelectRecruiter(recruiter)}
    >
      <div className="relative flex min-w-full flex-col gap-4">
        <div
          onClick={() => toggleRecruiter(recruiter)}
          className={cn(
            'absolute right-0',
            isSavedRecruiterCompany(recruiter) && 'text-[#A50000]',
          )}
        >
          <SavedStatusIcon recruiter={recruiter} />
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-xs">
            {!!recruiter.posted_on_month_day && (
              <>
                {t('posted')} {recruiter.posted_on_month_day}
              </>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-start gap-4">
          {/* <CompanyImage recruiter={recruiter} /> */}

          <div className="flex flex-col gap-1">
            <h4 className="pr-10 font-extrabold">{recruiter.name}</h4>
            <div className="flex flex-row text-xs">
              <div className="whitespace-nowrap">{recruiter.city_state}</div>
            </div>
          </div>
        </div>
        <p className="line-clamp-3 text-xs">{description}</p>
      </div>
    </li>
  )
}

const SavedStatusIcon = ({ recruiter }) => {
  const { isSavedRecruiterCompany } = useRecruiterSearch()

  if (isSavedRecruiterCompany(recruiter)) return <HeartSolidIcon />
  return <HeartOutlineIcon />
}
// function CompanyImage({ recruiter }) {
//   const [imageLoaded, setImageLoaded] = React.useState(false)
//   const { debug } = useRecruiterSearch()

//   const handleImageLoaded = () => {
//     if (!recruiter.logo_url) return

//     setImageLoaded(true)
//   }

//   const showInitials = !imageLoaded || !recruiter.logo_url

//   return (
//     <DebugInfo obj={recruiter} debug={debug}>
//       <div>
//         {showInitials && (
//           <div
//             className="flex h-10 w-10 items-center justify-center rounded bg-gray-100 text-3xl font-bold text-white text-opacity-80 opacity-80"
//             style={{ backgroundColor: recruiter.company_color }}
//           >
//             {recruiter.company_name[0]}
//           </div>
//         )}
//         {
//           <img
//             src={recruiter.logo_url}
//             alt={recruiter.company_name}
//             className="h-10 w-10 rounded object-cover"
//             style={{ display: showInitials ? 'none' : 'block' }}
//             onLoad={() => handleImageLoaded(true)}
//           />
//         }
//       </div>
//     </DebugInfo>
//   )
// }
