import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react'
import { usePDF } from '@react-pdf/renderer'
import Template1 from './Template1'
import { apiPost, apiPut } from '../../util/api'

const ResumeContext = createContext()

export const useResume = () => {
  return useContext(ResumeContext)
}

const ResumeProvider = ({ resume_json, document_id, dev_mode, children }) => {
  const [resumeDocument, setResumeDocument] = useState(resume_json)
  const documentId = document_id

  // use hook for fine-grained control over PDF rendering
  const [pdf, renderPdf] = usePDF({
    document: <Template1 resumeDocument={resumeDocument} />,
  })

  // Re-render the PDF
  const updateResume = useCallback(() => {
    renderPdf(<Template1 resumeDocument={resumeDocument} />)
  }, [resumeDocument, renderPdf])

  // Automatically re-render pdf every time resumeDocument changes
  useEffect(() => {
    updateResume()
  }, [resumeDocument, updateResume])

  // Persist resume data to the server
  const persistResume = async () => {
    if (documentId) {
      await saveExistingResume()
    } else {
      await saveNewResume()
    }
  }

  const saveNewResume = async () => {
    let response
    try {
      response = await apiPost('/resume/builder/', { resume: resumeDocument })
    } catch (e) {
      console.error('Error saving new resume', e)
      return
    }
    if (response.status === 200) {
      window.location.href = '/resume/builder/' + response.data.document_id
    }
  }

  const saveExistingResume = async () => {
    let response
    try {
      response = await apiPut(`/resume/builder/${documentId}`, {
        resume: resumeDocument,
      })
    } catch (e) {
      console.error('Error saving existing resume', e)
      return
    }
    if (response.status === 200) {
      console.log('Resume saved successfully', response)
    } else {
      console.error('Error saving existing resume', response)
    }
  }

  const value = {
    pdf,
    documentId,
    persistResume,
    resumeDocument,
    setResumeDocument,
    devMode: dev_mode,
  }

  return (
    <ResumeContext.Provider value={value}>{children}</ResumeContext.Provider>
  )
}
export default ResumeProvider
