import * as React from 'react'
import { CoachHeader, CoachHeaderBold } from '../CoachHeader'
import HeadShot from './HeadShot'
import { AccoladeMeetYourCoach } from './Accolade'
import { TranslationProvider } from '../../shared/TranslationProvider'
import translations from '../locales.json'
import { useTranslation } from '../../../hooks/useTranslation'
import { cn } from '../../../lib/utils'
import parse from 'html-react-parser'

const Index = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.coach_services.OutplacementBenefits.MeetYourCoach"
    >
      <MeetYourCoach {...props} />
    </TranslationProvider>
  )
}
export default Index

function MeetYourCoach({
  coach,
  coach_photo,
  transparent_coach_photo,
  coach_quote,
  coach_bio,
  accolades,
}) {
  const { yearsExperience, numClientsSupported } = accolades

  const quote =
    coach_quote || 'Lorem ipsum dolor sit amet, persius consequat ut eos.'

  const satisfactionRating =
    accolades.satisfactionRating >= 90 ? accolades.satisfactionRating : 90

  const { t } = useTranslation()

  const coachImage = transparent_coach_photo || coach_photo

  return (
    <div className="m-4 flex flex-col gap-10 font-roboto xl:m-12">
      <div className="flex flex-col gap-6">
        <CoachHeader className="mb-8 flex flex-col xl:block">
          {t('header.meet_your')}
          <CoachHeaderBold>
            {' '}
            {t('header.personal_career_coach')}
          </CoachHeaderBold>
        </CoachHeader>

        <div className="grid grid-cols-3 gap-y-4 rounded-[20px] bg-[#E9F2E2] px-2 pb-0 pt-2 xl:static xl:grid-cols-[340px_repeat(3,_minmax(0,_1fr))] xl:gap-x-6 xl:px-8 xl:pt-8">
          <div className="relative flex items-end xl:col-span-1 xl:row-span-2">
            <div className="absolute -left-2 xl:left-0">
              <HeadShot
                className="w-[7.5rem] xl:w-[18rem]"
                coachImage={coachImage}
              />
            </div>
          </div>

          <div className="col-span-2 flex flex-col justify-around xl:col-span-3 xl:items-start">
            <div className="block font-roboto text-[30px] font-bold uppercase text-intoo-green-coachcta xl:text-[32px]">
              {coach.first_name + ' ' + coach.last_name}
            </div>
          </div>
          <AccoladeCards
            yearsExperience={yearsExperience}
            numClientsSupported={numClientsSupported}
            satisfactionRating={satisfactionRating}
            className={cn('invisible fixed mb-8 xl:visible xl:static xl:flex')}
          />
        </div>

        <div className="col-span-3 grid grid-cols-3 gap-6 xl:hidden">
          <AccoladeCards
            yearsExperience={yearsExperience}
            numClientsSupported={numClientsSupported}
            satisfactionRating={satisfactionRating}
          />
        </div>

        <div className="grid grid-cols-11 font-roboto xl:gap-20">
          <div className="col-span-11 mt-6 flex flex-col xl:col-span-6 xl:mt-0">
            <div className="text-3.5xl font-bold text-intoo-green-coachcta">
              {t('about.about_coach', { first_name: coach.first_name })}:
            </div>
            <p>{parse(coach_bio)}</p>
          </div>
          <div className="order-first col-span-12 flex max-h-[17rem] items-center justify-center rounded-[20px] bg-[#E0F2F9] bg-intoo-logo bg-right bg-no-repeat p-4 xl:order-last xl:col-span-5">
            <div className="font-roboto text-lg font-semibold italic text-intoo-blue-medium">
              &ldquo;{quote}&rdquo;
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const AccoladeCards = ({
  yearsExperience,
  numClientsSupported,
  satisfactionRating,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <AccoladeMeetYourCoach
        rating={`${yearsExperience}+`}
        title={t('accolades.yearsExperience.title')}
        className={className}
      />
      <AccoladeMeetYourCoach
        rating={`${numClientsSupported}+`}
        title={t('accolades.clientsSupported.title')}
        className={className}
      />
      <AccoladeMeetYourCoach
        rating={`${satisfactionRating}%`}
        title={t('accolades.satisfactionRating.title')}
        className={className}
      />
    </>
  )
}
