import * as React from 'react'

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '../../../lib/ui/form'

import { Checkbox } from '../../../lib/ui/checkbox'

export default function FilterCheckbox({
  name,
  control,
  label,
  description,
  itemLabel,
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="mr-8 sm:mr-12">
          <div>
            <FormLabel>{label}</FormLabel>
            <FormDescription className="sr-only">{description}</FormDescription>
          </div>
          <FormField
            key={name}
            control={control}
            name={name}
            render={({ field }) => {
              return (
                <FormItem
                  key={name}
                  className="flex flex-row items-start space-x-3 space-y-0"
                >
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  {/* TODO: Remove global label font-weight */}
                  <FormLabel className="!font-normal !leading-none *:text-sm sm:text-base">
                    {itemLabel}
                  </FormLabel>
                </FormItem>
              )
            }}
          />
        </FormItem>
      )}
    />
  )
}
