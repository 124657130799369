import * as React from 'react'
import { Button } from '../../../lib/ui/button'
import { cn } from '../../../lib/utils'
import LeaveSiteWarning from '../../shared/LeaveSiteWarning'

export function RecommendedJobsCard({ recommended_jobs, className }) {
  let companyColors = ['#A93226', '#633974', '#1A5276']
  let companyColorMap = {}
  let colorIndex = 0

  return (
    <div
      className={cn(
        'relative rounded-[20px] border border-[#B1B1B1] p-6 shadow-md',
        className,
      )}
    >
      <div className="flex h-full flex-col gap-[12px] text-cod-gray-800">
        <div className="mb-[12px] text-2xl font-bold text-primary">
          Recommended Job Postings For You
        </div>
        <div className="flex flex-col gap-[16px] md:gap-[24px]">
          {recommended_jobs.map((job, index) => {
            let companyColor = companyColorMap[job['company']]
            if (!companyColor) {
              companyColor = companyColors[colorIndex % companyColors.length]
              companyColorMap[job['company']] = companyColor
              colorIndex++
            }

            return (
              <div key={index} className="flex grid grid-cols-12 items-center">
                {job['logo'] ? (
                  <div className="col-span-2">
                    <img
                      src={job['logo']}
                      alt={job['company']}
                      className="w-[75px]"
                    />
                  </div>
                ) : (
                  <div className="col-span-2">
                    <div
                      className="flex h-[50px] w-[50px] flex-grow items-center justify-center text-2xl text-white"
                      style={{
                        backgroundColor: companyColor,
                      }}
                    >
                      {job['company'][0].toUpperCase()}
                    </div>
                  </div>
                )}
                <div className="col-span-7 flex flex-col">
                  <div className="font-bold">{job['title']}</div>
                  <div>
                    {job['company']} - {job['location']}
                  </div>
                </div>
                <div className="col-span-3">
                  <LeaveSiteWarning url={job['url']}>
                    <Button variant="outline" asChild>
                      <a>View</a>
                    </Button>
                  </LeaveSiteWarning>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
