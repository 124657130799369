import React from 'react'
import { useTranslation } from './../../hooks/useTranslation'
import { TranslationProvider } from './../shared/TranslationProvider'
import PersonalBrandProvider, {
  usePersonalBrand,
} from './PersonalBrandProvider'
import translations from './locales.json'
import RightNav from './RightNav'
import ExerciseList from './ExerciseList'

const PersonalBrandInner = () => {
  const { t } = useTranslation('index')
  const { exercises } = usePersonalBrand()

  return (
    <div className="home-container flex flex-col md:col-span-9 md:m-12">
      <div className="tw-enable min-h-[70vh] font-[Roboto] lg:flex lg:flex-row">
        <div className="flex-grow">
          <h1 className="mb-4 !font-roboto-slab text-2xl text-[32px] font-medium text-black">
            <span className="font-light">{t('access')}</span>{' '}
            {t('marketing_toolkit')}
          </h1>
          <div className="mb-4">{t('personal_brand_intro_p1')}</div>
          <div className="mb-4">{t('personal_brand_intro_p2')}</div>
          <div className="mb-6">{t('personal_brand_intro_p3')}</div>
          <ExerciseList exercises={exercises} />
        </div>
      </div>
    </div>
  )
}

const PersonalBrand = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.PersonalBrand"
    >
      <PersonalBrandProvider {...props}>
        <div className="flex flex-col font-roboto text-cod-gray-800 lg:grid lg:grid-cols-12">
          <PersonalBrandInner />
          <RightNav />
        </div>
      </PersonalBrandProvider>
    </TranslationProvider>
  )
}

export default PersonalBrand
