import React, { useState, useEffect } from 'react'
import ResumeProvider, { useResume } from './ResumeProvider'
import { Textarea } from '../../lib/ui/textarea'
import { Button } from '../../lib/ui/button'
import {
  TabNav,
  TabPanel,
  PanelContainer,
  ButtonContainer,
  TabButton,
} from '../../lib/ui/TabNav'
import {
  DocumentMagnifyingGlassIcon,
  IdentificationIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline'
import Editor from './Editor'
import PdfViewerPanel from './PdfViewerPanel'

const ResumeBuilder = ({ ...props }) => {
  return (
    <ResumeProvider {...props}>
      <ResumeBuilderInner />
    </ResumeProvider>
  )
}
export default ResumeBuilder

const ResumeBuilderInner = () => {
  const { devMode } = useResume()

  return (
    <div className="flex w-full flex-col md:flex-row">
      <div className="md:basis-3/5">
        <TabNav
          className="px-4 pt-4 lg:px-8 xl:px-12"
          defaultActiveTab="details"
        >
          <ButtonContainer>
            <TabButton
              buttonClass="text-[#1C6F29]"
              name="chooseTemplate"
              text="Choose Template"
              Icon={DocumentMagnifyingGlassIcon}
            />
            <TabButton
              name="details"
              text="Details"
              Icon={IdentificationIcon}
            />
            <TabButton
              name="review"
              text="Review"
              Icon={ClipboardDocumentCheckIcon}
            />
            {devMode && (
              <TabButton name="text" text="Raw JSON" Icon={DocumentTextIcon} />
            )}
          </ButtonContainer>
          <PanelContainer className="min-h-[91vh] pt-8">
            <TabPanel name="chooseTemplate">CHOOSE TEMPLATE</TabPanel>
            <TabPanel name="details">
              <Editor />
            </TabPanel>
            <TabPanel name="review">
              <ReviewPanel />
            </TabPanel>
            <TabPanel name="text">
              <TextEditor />
            </TabPanel>
          </PanelContainer>
        </TabNav>
      </div>
      <div id="resume-pdf-preview" className="relative md:flex-grow">
        <div className="h-screen w-full md:sticky md:left-0 md:top-0">
          <PdfViewerPanel />
        </div>
      </div>
    </div>
  )
}

const ReviewPanel = () => {
  const { persistResume, documentId } = useResume()
  const resumeIsNew = !documentId

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-evenly">
        <Button onClick={persistResume}>
          {resumeIsNew ? 'Create' : 'Save'}
        </Button>
      </div>
    </div>
  )
}

const TextEditor = () => {
  const { resumeDocument, setResumeDocument } = useResume()
  const [editorResume, setEditorResume] = useState(
    JSON.stringify(resumeDocument, null, 2),
  )

  const sync = () => {
    const updatedResume = JSON.parse(editorResume)
    setResumeDocument(updatedResume)
  }

  useEffect(() => {
    setEditorResume(JSON.stringify(resumeDocument, null, 2))
  }, [resumeDocument])

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-row justify-evenly">
        <Button onClick={sync}>Sync</Button>
      </div>
      <Textarea
        className="h-full w-full"
        value={editorResume}
        onChange={(e) => setEditorResume(e.target.value)}
      ></Textarea>
    </div>
  )
}
