import * as React from 'react'
import { ActionPlanCard } from '../cards/ActionPlanCard'
import { MyJobsCard } from '../cards/MyJobsCard'
import { AylaCard } from '../cards/AylaCard'
import { QuoteCard } from '../cards/QuoteCard'
import { RecommendedJobsCard } from '../cards/RecommendedJobsCard'
import { RecommendedBlogsCard } from '../cards/RecommendedBlogsCard'
import { RecommendedCard } from '../cards/RecommendedCard'

export function Solo({
  tracker_time_period,
  applied_job_count,
  quote,
  recommended_jobs,
  recommended_blogs,
  general_recommendations,
}) {
  return (
    <>
      <div className="flex flex-col gap-[32px] md:grid md:grid-cols-7">
        <ActionPlanCard
          tracker_time_period={tracker_time_period}
          className="md:col-span-3"
        />
        <MyJobsCard
          applied_job_count={applied_job_count}
          className="md:col-span-2"
        />
        <AylaCard className="md:col-span-2" />
      </div>
      <QuoteCard quote={quote} />
      <div className="flex flex-col gap-[32px] md:grid md:grid-cols-7">
        <RecommendedJobsCard
          recommended_jobs={recommended_jobs}
          className="md:col-span-3"
        />
        <RecommendedBlogsCard
          recommended_blogs={recommended_blogs}
          className="md:col-span-4"
        />
      </div>
      <div className="relative flex flex-col">
        <div className="mb-[8px] text-2xl font-bold text-primary">
          Recommended For You
        </div>
        <div className="mb-[24px]">
          Explore articles, exercises, quizzes, and much more that are
          recommended for you.
        </div>
        <RecommendedCard general_recommendations={general_recommendations} />
      </div>
    </>
  )
}
