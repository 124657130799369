import React from 'react'
import { BuilderIcon, ExerciseIcon, ChecklistIcon } from './icons'
import { usePersonalBrand } from './PersonalBrandProvider'

const RightNavListElement = ({ exercise }) => {
  const {
    right_nav_title,
    path,
    type,
    alt_path,
    alt_path_description,
    alt_icon,
  } = exercise

  const { pitch, pitch_complete } = usePersonalBrand()

  const altIcon =
    alt_icon === 'checklist' ? (
      <ChecklistIcon className="mx-2 h-12 w-12 stroke-white stroke-2" />
    ) : (
      <ExerciseIcon className="mx-2 h-12 w-12 stroke-white stroke-2" />
    )
  if (type === 'builder') {
    return (
      <div className="flex flex-col gap-2">
        <div className="text-lg">{right_nav_title}</div>
        {pitch && pitch_complete ? (
          <a href={path} className="!w-fit text-white">
            {pitch.content.text}
          </a>
        ) : (
          <a
            href={path}
            className="hover:!bg-white-darker-30 flex !w-fit items-center justify-center rounded-3xl bg-white px-4 py-3 text-base font-medium text-primary hover:text-primary focus:text-primary active:text-primary sm:py-2"
          >
            Start Builder{' '}
            <BuilderIcon className="ml-2 stroke-primary stroke-2" />
          </a>
        )}
        {alt_path && (
          <div>
            <a
              href={alt_path}
              className="ga-trackable flex flex-row text-white"
            >
              {altIcon}
              {alt_path_description}
            </a>
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div className="flex flex-col gap-2">
        <div className="text-lg">{right_nav_title}</div>
        <a
          href={path}
          className="hover:!bg-white-darker-30 flex !w-fit items-center justify-center rounded-3xl bg-white px-4 py-3 text-base font-medium text-primary hover:text-primary focus:text-primary active:text-primary sm:py-2"
        >
          Start Exercise{' '}
          <ExerciseIcon className="ml-2 stroke-primary stroke-2" />
        </a>
        {alt_path && (
          <div>
            <a
              href={alt_path}
              className="ga-trackable flex flex-row text-white"
            >
              {altIcon}
              {alt_path_description}
            </a>
          </div>
        )}
      </div>
    )
  }
}

export default RightNavListElement
