import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  createContext,
} from 'react'
import { useFilters } from './useFilters'
import { useRecruiterSearchQuery } from './useRecruiterSearchQuery'
import { useRecruiterSearchParams } from './useRecruiterSearchParams'
import { useRecruiterSearchForm } from './useRecruiterSearchForm'
import { apiPost, getApi, apiDelete } from '../../../util/api'

const RecruiterSearchContext = createContext()

export function useRecruiterSearch() {
  return useContext(RecruiterSearchContext)
}

export function RecruiterSearchProvider({
  search_results,
  total_results,
  total_pages,
  next_page,
  allow_debug,
  industries,
  saved_recruiter_ids,
  location,
  children,
}) {
  const [selectedRecruiterDrawerOpen, setSelectedRecruiterDrawerOpen] =
    useState(false)
  const resultDetailScrollRef = useRef(null)
  const [selectedRecruiter, setSelectedRecruiter] = useState(null)
  const [noResultQuery, setNoResultQuery] = useState('')
  const [viewMoreButtonDisabled, setViewMoreButtonDisabled] = useState(false)
  const [formDisabled, setFormDisabled] = useState(false)
  const { urlSearchParams, setUrlSearchParams, paramString, lockParamString } =
    useRecruiterSearchParams(location)
  const [savedRecruiterIds, setSavedRecruiterIds] = useState(
    saved_recruiter_ids || [],
  )
  const debug = allow_debug && urlSearchParams.has('debug')
  const [previousFirstRecruiterId, setPreviousFirstRecruiterId] = useState(null)
  const addRecruiterUrl = '/job-search/recruiters/my_recruiters'

  const {
    queryStatusMessage,
    pagesToShow,
    setPagesToShow,
    setPrefetchedPages,
    recruiterResults,
    amountShown,
    amountTotal,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isPending,
    showViewMoreButton,
  } = useRecruiterSearchQuery({
    paramString,
    initialData: {
      pageParams: [1],
      pages: [
        {
          results: search_results,
          total_results: total_results,
          total_pages: total_pages,
          next_page: next_page,
        },
      ],
    },
  })

  const { form, onSubmit } = useRecruiterSearchForm({
    urlSearchParams,
    lockParamString,
    noResultQuery,
    setPagesToShow,
    setPrefetchedPages,
  })

  const { hasFilters, resetFilters } = useFilters({ form, setUrlSearchParams })

  useEffect(() => {
    if (isFetching && pagesToShow === 1 && !isFetchingNextPage) {
      setFormDisabled(true)
    } else {
      setFormDisabled(false)
    }
  }, [pagesToShow, isFetching, isFetchingNextPage, isPending])

  const firstRecruiter = recruiterResults[0]
  useEffect(() => {
    if (firstRecruiter && firstRecruiter.id !== previousFirstRecruiterId) {
      setPreviousFirstRecruiterId(firstRecruiter.id)
      setSelectedRecruiter(firstRecruiter)
    }
  }, [firstRecruiter, previousFirstRecruiterId])

  const urlSearchParamsWhat = urlSearchParams.get('what')
  useEffect(() => {
    if (recruiterResults.length === 0) {
      setNoResultQuery(urlSearchParamsWhat)
    } else {
      setNoResultQuery('')
    }
  }, [recruiterResults.length, urlSearchParamsWhat, paramString])

  const handleSelectRecruiter = (recruiter) => (event) => {
    event.stopPropagation()
    resultDetailScrollRef.current?.scrollTo(0, 0, { behavior: 'smooth' })
    setSelectedRecruiter(null)
    setSelectedRecruiter(recruiter)
    setSelectedRecruiterDrawerOpen(true)
  }

  const handleViewMore = async () => {
    setViewMoreButtonDisabled(true)
    setPagesToShow((prev) => prev + 1)
    await fetchNextPage()
    setViewMoreButtonDisabled(false)
  }

  const fetchSavedRecruiterIds = async () => {
    try {
      const api = getApi()
      const response = await api.get(
        '/job-search/recruiter_search/saved_recruiter_ids',
      )
      if (response.status === 200) {
        setSavedRecruiterIds(response.data)
      }
    } catch (error) {
      console.error('Failed to fetch saved recruiter ids:', error)
    }
  }

  const addRecruiterContact = async (contact, recruiterCompany) => {
    const saved_recruiter = {
      recruiter_company_id: recruiterCompany.id,
      recruiter_name: contact.full_name,
      email: contact.email,
    }

    try {
      const response = await apiPost(addRecruiterUrl, {
        saved_recruiter: saved_recruiter,
      })
      if (response.status === 201) {
        await fetchSavedRecruiterIds()
      }
    } catch (error) {
      const recruiter = {
        recruiter_company_id: recruiterCompany.id,
        email: contact.email,
      }
      const index =
        savedRecruiterIds.indexOf(recruiter)(index > -1) &&
        setSavedRecruiterIds([
          ...savedRecruiterIds.slice(0, index),
          ...savedRecruiterIds.slice(index + 1),
        ])

      console.error('Failed to save recruiter:', error)
    }
  }

  const removeFromMyRecruiters = async (recruiter) => {
    const savedRecruiter = savedRecruiterIds.find(
      (r) =>
        r.recruiter_company_id === recruiter.id && r.email === recruiter.email,
    )

    let response

    try {
      response = await apiDelete(
        `/job-search/recruiters/my_recruiters/${savedRecruiter?.id}`,
      )
    } catch (error) {
      console.log(error)
    }
    if (response.status === 200) {
      setSavedRecruiterIds((prev) =>
        prev.filter(
          (ele) =>
            ele.recruiter_company_id !== savedRecruiter?.recruiter_company_id &&
            ele.email !== savedRecruiter?.email,
        ),
      )
    } else {
      throw new Error('Error deleting item')
    }
  }

  const addToMyRecruiters = async (recruiter) => {
    const recruiterInfo = {
      recruiter_company_id: recruiter.id,
      email: recruiter.email,
    }
    setSavedRecruiterIds([...savedRecruiterIds, recruiterInfo])

    try {
      const response = await apiPost(addRecruiterUrl, {
        saved_recruiter: {
          recruiter_company_id: recruiter.id,
        },
      })
      if (response.status === 201) {
        await fetchSavedRecruiterIds()
      }
    } catch (error) {
      const index =
        savedRecruiterIds.indexOf(recruiterInfo)(index > -1) &&
        setSavedRecruiterIds([
          ...savedRecruiterIds.slice(0, index),
          ...savedRecruiterIds.slice(index + 1),
        ])

      console.error('Failed to save recruiter:', error)
    }
  }

  const isSavedRecruiter = (recruiter) => {
    return savedRecruiterIds.some(
      (r) =>
        r.recruiter_company_id === recruiter.id && r.email === recruiter.email,
    )
  }

  const isSavedRecruiterCompany = (recruiter) => {
    return savedRecruiterIds.some(
      (r) => r.recruiter_company_id === recruiter.id,
    )
  }

  const resetSelectedRecruiterState = () => {
    setSelectedRecruiter(null)
  }

  const value = {
    form,
    formDisabled,
    onSubmit,
    setSelectedRecruiter,
    selectedRecruiter,
    hasFilters,
    resetFilters,
    selectedRecruiterDrawerOpen,
    setSelectedRecruiterDrawerOpen,
    resultDetailScrollRef,
    noResultQuery,
    handleSelectRecruiter,
    handleViewMore,
    urlSearchParams,
    queryStatusMessage,
    setNoResultQuery,
    recruiterResults,
    amountShown,
    amountTotal,
    viewMoreButtonDisabled,
    showViewMoreButton,
    savedRecruiterIds,
    setSavedRecruiterIds,
    debug,
    industries,
    addToMyRecruiters,
    isSavedRecruiter,
    resetSelectedRecruiterState,
    location,
    addRecruiterContact,
    removeFromMyRecruiters,
    isSavedRecruiterCompany,
  }

  return (
    <RecruiterSearchContext.Provider value={value}>
      {children}
    </RecruiterSearchContext.Provider>
  )
}
