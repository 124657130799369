import React, { useContext, createContext, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  selectGoal,
  selectTimeline,
  selectProgressPercent,
  selectTrackerSectionInfo,
} from './ActionPlanSlice'

const ActionPlanContext = createContext()

export const useActionPlan = () => {
  return useContext(ActionPlanContext)
}

export const ActionPlanProvider = ({ s3_base_url, children }) => {
  const goal = useSelector(selectGoal)
  const timeline = useSelector(selectTimeline)
  const progressPercent = useSelector(selectProgressPercent)
  const trackerSectionInfo = useSelector(selectTrackerSectionInfo)

  const completionAnimationUrl =
    s3_base_url + '/articles/images/article-complete.gif'

  const cacheCompletionAnimation = useCallback(async () => {
    if (!s3_base_url || !completionAnimationUrl) return
    const completionAnimation = new Image()
    completionAnimation.src = completionAnimationUrl
  }, [completionAnimationUrl, s3_base_url])

  useEffect(() => {
    cacheCompletionAnimation()
  }, [cacheCompletionAnimation])

  const value = {
    trackerSectionInfo,
    progressPercent,
    completionAnimationUrl,
    goal,
    timeline,
  }

  return (
    <ActionPlanContext.Provider value={value}>
      {children}
    </ActionPlanContext.Provider>
  )
}
