import { createSlice } from '@reduxjs/toolkit'

const actionPlanSlice = createSlice({
  name: 'actionPlan',
  initialState: {
    goal: {
      value: '',
    },
    timeline: {
      value: '',
    },
    progressPercent: {
      value: 0,
    },
    trackerSectionInfo: {
      value: [],
    },
  },
  reducers: {
    setGoal: (state, action) => {
      state.goal.value = action.payload
    },
    setTimeline: (state, action) => {
      state.timeline.value = action.payload
    },
    setProgressPercent: (state, action) => {
      state.progressPercent.value = action.payload
    },
    setTrackerSectionInfo: (state, action) => {
      state.trackerSectionInfo.value = action.payload
    },
  },
  selectors: {
    selectGoal: (state) => state.goal.value,
    selectTimeline: (state) => state.timeline.value,
    selectProgressPercent: (state) => state.progressPercent.value,
    selectTrackerSectionInfo: (state) => state.trackerSectionInfo.value,
  },
})

export default actionPlanSlice.reducer
export const {
  setGoal,
  setTimeline,
  setProgressPercent,
  setTrackerSectionInfo,
} = actionPlanSlice.actions
export const {
  selectGoal,
  selectTimeline,
  selectProgressPercent,
  selectTrackerSectionInfo,
} = actionPlanSlice.selectors
