import * as React from 'react'
import { CoachButton } from '../../coach_services/OutplacementBenefits/CoachButton.js'
import { cn } from '../../../lib/utils.js'
import { IntooColorIcon } from '../Icons'
import { HeadShotOld } from '../../coach_services/MeetYourCoach/HeadShot'
import { Button } from '../../../lib/ui/button'
import { useDashboard } from '../DashboardProvider'
import CoachInfoDialog from '../CoachInfoDialog'
import { useBenefits } from '../../coach_services/BenefitsProvider'
import CalendlyCallPopup, {
  CalendlyCallScheduled,
} from '../../coach_services/CalendlyCallPopup.js'

export function PremiumCoachCard({ coach, className }) {
  return (
    <div
      className={cn(
        'relative overflow-hidden rounded-[20px] border border-[#B1B1B1] p-6 shadow-md',
        className,
      )}
    >
      {coach.liaison ? (
        <LiaisonCard coach={coach} />
      ) : (
        <DedicatedCoachCard coach={coach} />
      )}
    </div>
  )
}

function LiaisonCard({ coach }) {
  const { setShowCoachInfoDialog } = useDashboard()
  return (
    <div className="flex h-full flex-col text-cod-gray-800">
      <div className="mb-[16px] block bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright bg-clip-text font-ibm-plex text-2xl font-bold uppercase text-transparent md:pr-[24px] md:text-4xl">
        {coach.name}
      </div>
      <p className="text-2xl">
        Meet your coach liaison, {coach.name}. We are working on your match and
        will assign your dedicated coach soon!
      </p>
      <div className="flex flex-row items-center justify-between md:mt-[-16px] md:items-end">
        <CoachButton onClick={() => setShowCoachInfoDialog(true)}>
          Get To Know Your Coach Liaison
        </CoachButton>

        <HeadShotOld
          className="h-[65px] w-[65px] md:mb-[-16px] md:h-[130px] md:w-[130px]"
          coachImage={coach.image}
        />
      </div>
      <IntooColorIcon className="absolute right-[0px] top-[0px] hidden md:block" />
    </div>
  )
}

function DedicatedCoachCard({ coach }) {
  const { calendlyEvent } = useBenefits()

  const orientationCallCompleted = calendlyEvent?.type === 'completed'

  const gapZero = calendlyEvent && !orientationCallCompleted

  const alignItems = orientationCallCompleted
    ? 'md:items-center'
    : 'md:items-end'

  const calendlyScheduledClasses = gapZero ? 'md:gap-0' : 'md:justify-between'
  const buttonContainerClasses = cn(
    'flex flex-col gap-[16px] md:flex-row',
    calendlyScheduledClasses,
    alignItems,
  )
  return (
    <div className="flex h-full flex-col gap-[16px] text-cod-gray-800">
      <div className="flex items-center justify-center gap-4">
        <HeadShotOld
          className="w-[110px]s h-[110px]"
          coachImage={coach.image}
        />
        <div className="flex shrink-[5] flex-col">
          <h2 className="mb-[16px] block bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright bg-clip-text font-ibm-plex text-2xl font-bold uppercase text-transparent md:pr-[24px] md:text-4xl">
            {coach.name}
          </h2>
          <h3 className="font-bold text-intoo-blue-medium md:text-2xl">
            Your Dedicated Career Coach
          </h3>
        </div>
      </div>
      <div className="flex flex-col gap-[16px]">
        {calendlyEvent ? (
          <p>
            Continue your journey with the help and support of your dedicated
            career coach.
          </p>
        ) : (
          <p>
            Get to know your coach and learn about the system and the services
            that are available to you.
          </p>
        )}
        <div className={buttonContainerClasses}>
          {!calendlyEvent && <CalendlyCallPopup eventType={'orientation'} />}
          {calendlyEvent && (
            <CalendlyCallScheduled
              eventType="orientation"
              calendlyEvent={calendlyEvent}
            />
          )}
          <CoachInfoDialog coach={coach}>
            <Button variant="outline">Learn More about {coach.name}</Button>
          </CoachInfoDialog>
        </div>
      </div>
      <IntooColorIcon className="absolute right-[0px] top-[0px] hidden md:block" />
    </div>
  )
}
