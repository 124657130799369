import React from 'react'
import ProgressContainer from './ProgressContainer'
import { useMediaQueryScreen } from '../../hooks/useMediaQuery'
import GoalTile from './GoalTile'

const ActionPlanInfo = ({ goal, timeline, getUserTrackerSections }) => {
  const isXlScreen = useMediaQueryScreen('xl')
  const isLgScreen = useMediaQueryScreen('lg')

  const actionPlanProgressClass = isLgScreen ? 'mb-[6px]' : 'hidden'
  return (
    <div className="track-info flex flex-col pb-2 md:gap-2">
      <div className="flex flex-col justify-between lg:flex-row">
        <div className="flex flex-col">
          <div className="mb-[20px] flex flex-col gap-8 xl:flex-row">
            <div className="flex flex-col justify-between">
              <h1 className="m-0 p-0 font-roboto-slab text-[32px] font-[700] leading-tight text-primary">
                Action Plan
              </h1>
              {isXlScreen && <h3>Your First Steps</h3>}
            </div>
            <GoalTile
              variant="goal"
              goal={goal}
              getUserTrackerSections={getUserTrackerSections}
            />
            <GoalTile
              variant="timeline"
              goal={timeline}
              timeline={timeline}
              getUserTrackerSections={getUserTrackerSections}
            />
          </div>
          {isXlScreen && <Subtitle goal={goal} />}
        </div>
        <div className="flex flex-col gap-4 font-roboto-slab md:min-w-max lg:items-center">
          <h2 className={actionPlanProgressClass}>Action Plan Progress</h2>
          <ProgressContainer />
        </div>
      </div>
      {!isXlScreen && (
        <div className="mt-4 flex flex-col gap-4">
          <h3>Your First Steps</h3>
          <Subtitle goal={goal} />
        </div>
      )}
    </div>
  )
}

const Subtitle = ({ goal }) => {
  return (
    <p>
      {`Welcome to Action Plan - your career transition plan for meeting your goal of ${goal
        .toLowerCase()
        .replace(
          /myself/,
          'yourself',
        )}. We've created a plan for you based on the insights you've already shared with us. As you dive into quizzes and assessments, your Action Plan adapts, adding and taking away resources to help you achieve your goal.`}
    </p>
  )
}

export default ActionPlanInfo
