import React from 'react'
import { Textarea } from '../../../lib/ui/textarea'
import { Input } from '../../../lib/ui/input'
import { Label } from '../../../lib/ui/label'
import { TrashIcon } from '@heroicons/react/24/outline'

export const newKey = () => Math.random().toString(36).substr(2, 9)

export const EditorHeading = ({ children }) => {
  return (
    <div className="mb-4 text-lg font-medium tracking-[0.014px] text-primary">
      {children}
    </div>
  )
}

export const ArrayFields = ({
  items,
  keyPrefix,
  onChange,
  onBlur,
  onDeleteClick = () => null,
}) => {
  return items.map((item, i) => (
    <div key={`${keyPrefix}-${i}`} className="flex flex-row">
      <Textarea
        rows="2"
        value={item}
        onChange={(e) => {
          const updatedItems = [...items]
          updatedItems[i] = e.target.value
          onChange(updatedItems)
        }}
        onBlur={onBlur}
      />
      <button
        onClick={() => onDeleteClick(i)}
        className="max-w-min text-[#b9b9b9]"
        aria-label="remove item"
      >
        <TrashIcon className="ml-2 w-8" />
      </button>
    </div>
  ))
}

export const ArrayInputs = ({
  items,
  keyPrefix,
  onChange,
  onBlur,
  onKeyPress = () => null,
  onDeleteClick = () => null,
}) => {
  return items.map((item, i) => (
    <div key={`${keyPrefix}-${i}`} className="flex flex-row">
      <Input
        value={item}
        name={`${keyPrefix}-arrayInput-${i}`}
        onKeyPress={onKeyPress}
        aria-label={`item ${i}`}
        onChange={(e) => {
          const updatedItems = [...items]
          updatedItems[i] = e.target.value
          onChange(updatedItems)
        }}
        onBlur={onBlur}
      />
      <button
        onClick={() => onDeleteClick(i)}
        className="max-w-min text-[#b9b9b9]"
        aria-label={`remove item ${i}`}
      >
        <TrashIcon className="ml-2 w-8" />
      </button>
    </div>
  ))
}

export const EditorTextField = ({
  section,
  label,
  index,
  value,
  updateFunc,
  onBlur,
  containerClassName,
}) => {
  const fieldName = label?.toLowerCase()?.replace(/[^a-zA-Z]/g, '')
  const id = [section, fieldName, index].join('-')
  return (
    <div className={containerClassName}>
      <Label htmlFor={id}>{label}</Label>
      <Input
        onBlur={onBlur}
        value={value}
        id={id}
        onChange={(e) => updateFunc(e.target.value)}
      />
    </div>
  )
}
