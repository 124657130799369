import React from 'react'
import parse from 'html-react-parser'
import { cn } from '../../lib/utils'
import HighlightCommentProvider, {
  useHighlightComment,
} from './HighlightCommentProvider'
import CommentBox from './CommentBox'

export const NumberedList = ({ listObject }) => {
  return (
    <List
      titleClass="font-semibold"
      listClass="list-decimal list-inside font-semibold"
      listObject={listObject}
    />
  )
}

export const TitledBulletList = ({ listObject }) => {
  const { plugins } = listObject
  const listClass = `list-outside ${plugins ? plugins : 'list-disc'} space-y-4`

  return (
    <List
      titleClass="font-semibold"
      listClass={listClass}
      listObject={listObject}
    />
  )
}

export const TitledList = ({ listObject }) => {
  return (
    <List
      titleClass="font-semibold"
      listClass="list-outside space-y-4 ml-0 !list-none"
      listObject={listObject}
    />
  )
}

export const BulletedList = ({ listObject }) => {
  const listClass = `list-outside list-disc ${
    listObject.indent ? 'ml-5' : 'ml-4'
  }`
  return (
    <List
      divClass="mx-4"
      titleClass="font-semibold"
      listClass={listClass}
      listObject={listObject}
    />
  )
}

export const NestedBulletList = ({ listObject }) => {
  return (
    <List
      divClass="mx-4"
      titleClass="font-semibold"
      listClass="list-outside list-disc"
      listObject={listObject}
    />
  )
}

export const List = ({ divClass, titleClass, listClass, listObject }) => {
  const { content, children, title, plugins } = listObject
  const isNumberedList = listClass.includes('list-decimal')

  return (
    <div className={cn('mt-4 first:mt-0', divClass)}>
      {title && <div className={cn('mb-4', titleClass)}>{title}</div>}
      {isNumberedList ? (
        <ol className={cn('list-inside space-y-2', listClass)}>
          {children && (
            <HighlightCommentProvider locatorSuffix=".children">
              <ListItems items={children} plugins={plugins} />
            </HighlightCommentProvider>
          )}
          {content && (
            <HighlightCommentProvider locatorSuffix=".content">
              <ListItems items={content} plugins={plugins} />
            </HighlightCommentProvider>
          )}
        </ol>
      ) : (
        <ul className={cn('list-inside space-y-2', listClass)}>
          {children && (
            <HighlightCommentProvider locatorSuffix=".children">
              <ListItems items={children} plugins={plugins} />
            </HighlightCommentProvider>
          )}
          {content && (
            <HighlightCommentProvider locatorSuffix=".content">
              <ListItems items={content} plugins={plugins} />
            </HighlightCommentProvider>
          )}
        </ul>
      )}
    </div>
  )
}

export const ListItems = ({ items, plugins }) => {
  return (
    <>
      {items.map((item, index) => (
        <HighlightCommentProvider key={index} locatorSuffix={`[${index}]`}>
          <ListItem plugins={plugins} item={item} />
        </HighlightCommentProvider>
      ))}
    </>
  )
}

const ListItem = ({ plugins, item }) => {
  if (typeof item === 'string') {
    return <StringListItem plugins={plugins}>{item}</StringListItem>
  } else if (typeof item === 'object') {
    return <ObjectListItem item={item} />
  }
}

const StringListItem = ({ plugins, children }) => {
  const { actionProps, highlightClass } = useHighlightComment()
  const liClasses = plugins && plugins.join(' ')

  const content =
    plugins && plugins.includes('parse-html') ? parse(children) : children

  return (
    <li className={liClasses}>
      <span className={highlightClass} {...actionProps}>
        {plugins && plugins.includes('quoted') ? <q>{content}</q> : content}
      </span>
      <CommentBox />
    </li>
  )
}

const ObjectListItem = ({ item }) => {
  const { plugins, title, content, childPlugins, children } = item
  const { actionProps, highlightClass } = useHighlightComment()

  const text =
    plugins && plugins.includes('parse-html') ? parse(content) : content
  let divClass = highlightClass
  if (plugins) {
    divClass += plugins.join(' ')
  }
  return (
    <li>
      <div className="font-semibold">{title}</div>
      <div className={divClass} {...actionProps}>
        {text}
      </div>
      <CommentBox />
      {children && (
        <ul className="ml-4 mt-4 list-inside list-disc space-y-2">
          <HighlightCommentProvider locatorSuffix=".children">
            <ListItems items={children} plugins={childPlugins} />
          </HighlightCommentProvider>
        </ul>
      )}
    </li>
  )
}
