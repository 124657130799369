import React from 'react'
import Page from './Page'
import { useTranslation } from '../../hooks/useTranslation'
import { useExercise } from './ExerciseProvider'

const Exercise = () => {
  const { formattedExerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const pagesInfo = t('pages')
  const pagesArr = Object.values(pagesInfo)
  const numPages = pagesArr.length

  return (
    <div>
      {pagesArr.map((page, idx) => (
        <Page
          key={`page-${idx}`}
          idx={idx}
          pageInfo={page}
          numPages={numPages}
        />
      ))}
    </div>
  )
}
export default Exercise
