import React, { useState } from 'react'
import { Button } from '../../lib/ui/button'
import SetGoalForm from './SetGoalForm'
import { apiPatch, apiGet } from '../../util/api'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from '../../lib/ui/dialog'
import { store } from '../../lib/store'
import { setGoal, setProgressPercent } from './ActionPlanSlice'
import { getUserTrackerSections } from './index'
import { TargetIcon } from './TrackTileComponents/icons'

const SetGoalDialog = ({ showDialog, toggleDialog, goal, children }) => {
  const [newGoal, setNewGoal] = useState(goal)

  const updateGoal = async () => {
    const res = await apiPatch('/trackers/set_goal', {
      candidate: { candidate_profile_attributes: { interest: newGoal } },
    })
    const data = res.data
    if (res.status === 200) {
      store.dispatch(setGoal(data.trackerGoal))
      store.dispatch(setProgressPercent(data.trackerProgress))
      getUserTrackerSections()
      if (window.location.pathname === '/orientation/welcome') {
        const welcomeHTML = await apiGet('/orientation/welcome')
        if (welcomeHTML.status === 200) {
          const data = welcomeHTML.data
          const parser = new DOMParser()
          const doc = parser.parseFromString(data, 'text/html')
          const interestText = doc.getElementById('interest_text').innerHTML
          const interestTextParagraph = doc.getElementById(
            'interest_text_paragraph',
          ).innerHTML
          const profileText = doc.getElementById('profile_text').innerHTML
          if (interestText) {
            document.getElementById('interest_text').innerHTML = interestText
          }
          if (interestTextParagraph) {
            document.getElementById('interest_text_paragraph').innerHTML =
              interestTextParagraph
          }
          if (profileText) {
            document.getElementById('profile_text').innerHTML = profileText
          }
        }
      }
      return res
    } else {
      throw new Error('res not ok')
    }
  }

  const handleSave = () => {
    toggleDialog()
    if (newGoal) {
      updateGoal()
    }
  }
  return (
    <Dialog open={showDialog} onOpenChange={toggleDialog}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Set Your Goal</DialogTitle>
          <TargetIcon className="h-6 w-6" variant="light" />
        </DialogHeader>
        <SetGoalForm newGoal={goal} setNewGoal={setNewGoal} />
        <DialogFooter>
          <Button onClick={handleSave}>Save</Button>
        </DialogFooter>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
export default SetGoalDialog
