import React from 'react'
import { cn } from '../../../lib/utils'

const IntooSvg = ({ className }) => {
  return (
    <svg
      width="266"
      height="330"
      viewBox="0 0 266 330"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('', className)}
    >
      <path
        d="M184.694 174.65C172.572 146.928 166.291 121.186 165.661 98.1042C164.912 75.1784 169.667 54.8272 179.548 37.44C190.873 17.128 209.366 0.90952 234.278 -10.5496C246 -15.8711 257.777 -15.8646 265.918 -3.50148C274.233 9.39134 264.549 20.1566 251.269 26.9566C239.626 32.8892 229.826 39.4956 221.71 46.9915C221.24 47.2607 220.881 47.7272 220.421 48.1864C205.958 61.8787 197.54 78.3355 196.156 97.6022C194.8 113.944 198.316 132.349 207.217 153.004C239.501 228.085 315.2 227.069 319.055 181.233C316.981 230.098 217.98 236.871 184.694 174.65Z"
        fill="white"
        fillOpacity="0.45"
      />
      <path
        d="M46.5997 287.257C-9.7093 180.721 4.20909 67.1267 130.899 39.2207C146.185 35.8304 162.776 35.3975 179.808 37.3683C169.883 54.743 165.115 75.1098 165.905 98.0051C160.678 98.4402 155.432 99.2778 150.282 100.286C148.439 100.584 146.616 100.971 144.883 101.441C59.8235 120.806 6.22719 215.838 54.9046 301.906C52.0021 297.093 49.2668 292.204 46.5997 287.257Z"
        fill="white"
        fillOpacity="0.45"
      />
      <path
        d="M318.073 176.078C318.028 175.059 318.073 174.122 317.926 173.084C316.006 117.301 272.148 69.47 219.606 48.1424C220.063 47.6607 220.449 47.1738 220.949 46.9458C293.878 70.8254 361.45 132.437 354.616 197.896C347.34 267.76 231.539 282.731 183.937 173.811C217.065 235.591 315.805 228.821 317.933 180.28C318.073 179.977 318.006 179.571 317.973 179.255C318.013 178.23 318.052 177.192 318.073 176.078Z"
        fill="white"
        fillOpacity="0.45"
      />
      <path
        d="M318.429 172.862C315.162 136.077 258.659 101.514 196.09 97.4191C197.517 78.3349 205.863 62.0665 220.298 48.5312C272.759 69.7454 316.536 117.327 318.429 172.862Z"
        fill="white"
        fillOpacity="0.45"
      />
      <path
        d="M325.761 418.878C322.192 443.761 281.583 470.27 225.592 448.158C173.093 427.41 99.3907 376.378 54.613 302.404L54.6079 302.316C6.08185 216.746 59.5998 122.219 144.477 102.927C76.1732 119.249 42.6671 184.909 74.3455 240.226C124.146 327.317 268.764 353.227 268.764 353.227C301.143 360.521 330.616 385.227 325.761 418.878Z"
        fill="white"
        fillOpacity="0.45"
      />
    </svg>
  )
}
export default IntooSvg
