import * as React from 'react'

import { useRecruiterSearch } from './RecruiterSearchProvider'
import { ScrollArea } from '../../../lib/ui/scroll-area'
import { useIsDesktop, useIsMobile } from '../../../hooks/useMediaQuery'
import ArrowLongLeftIcon from '../JobSearchPage/ArrowLongLeftIcon'
import { useTranslation } from '../../../hooks/useTranslation'
import { ViewMoreButton } from './buttons'
import { Separator } from '../../../lib/ui/separator'
import RecruiterCard from './RecruiterCard'
import RecruiterDetails, {
  RecruiterDetailsTop,
  RecruiterDetailsBottom,
} from './RecruiterDetails'
import { Drawer, DrawerContent } from '../../../lib/ui/drawer'
import { Button } from '../../../lib/ui/button'

export default function SearchResults() {
  const { t } = useTranslation('SearchResults')
  const {
    selectedRecruiterDrawerOpen,
    setSelectedRecruiterDrawerOpen,
    resultDetailScrollRef,
    noResultQuery,
    recruiterResults,
  } = useRecruiterSearch()
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const scrollAreaRef = React.useRef(null)

  React.useEffect(() => {
    const handleResize = () => {
      if (scrollAreaRef.current) {
        const rect = scrollAreaRef.current.getBoundingClientRect()
        const viewHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight,
        )
        const scrollHeight = document.documentElement.scrollHeight
        const scrollTop = document.documentElement.scrollTop
        const remainingScroll = scrollHeight - scrollTop - viewHeight

        let dynamicHeight = viewHeight - rect.top - 16
        const footerHeight = 160

        if (remainingScroll < footerHeight) {
          dynamicHeight -= footerHeight - remainingScroll
        }

        scrollAreaRef.current.style.height = `${dynamicHeight}px`
      }
    }

    window.addEventListener('scroll', handleResize)
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('scroll', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const showResults = recruiterResults.length > 0

  if (noResultQuery) {
    return (
      <div className="mx-6 my-12 flex flex-col items-center justify-center ">
        <p className="text-balance text-center text-xl text-gray-500">
          {t('no_results')}
        </p>
        <p className="text-balance text-center text-xl text-gray-500">
          {t('no_results_suggestions')}
        </p>
      </div>
    )
  }

  if (!showResults) {
    return (
      <div className="m-12 flex flex-col items-center justify-center ">
        <p className="text-balance text-center text-xl text-gray-500">
          {t('get_started')}
        </p>
      </div>
    )
  }

  if (isDesktop) {
    return (
      <div className="flex w-full flex-row gap-2">
        <div className="min-h-[80vh] min-w-96 basis-2/5 pr-4">
          <RecruiterCardList />
        </div>

        <div
          ref={scrollAreaRef}
          className="sticky top-32 flex max-h-[825px] basis-3/5 flex-col overflow-hidden rounded-2xl border-2"
        >
          <div className="shadow-bottom relative bg-primary p-4 shadow-md">
            <div className="flex flex-col gap-4">
              <RecruiterDetailsTop />
            </div>
          </div>
          <ScrollArea
            className="mb-4 mr-1 mt-1 h-auto px-4"
            viewPortRef={resultDetailScrollRef}
          >
            <div className="py-4">
              <RecruiterDetailsBottom />
            </div>
          </ScrollArea>
        </div>
      </div>
    )
  }
  return (
    <div className="flex w-full flex-col">
      <RecruiterCardList />

      <Drawer
        direction="right"
        open={selectedRecruiterDrawerOpen}
        onClose={setSelectedRecruiterDrawerOpen}
      >
        <DrawerContent>
          <ScrollArea className="h-screen">
            <div className={`${isMobile ? 'mt-4' : 'mt-40'}`}>
              <Button
                type="button"
                variant="link"
                className="flex flex-row gap-2"
                onClick={() => setSelectedRecruiterDrawerOpen(false)}
              >
                <ArrowLongLeftIcon />
                {t('return_to_list_of_recruiters')}
              </Button>
            </div>
            <div className={`mb-16 ${!isMobile ? 'p-4' : ''} font-roboto`}>
              <RecruiterDetails />
            </div>
          </ScrollArea>
        </DrawerContent>
      </Drawer>
      <Separator className="mt-16" />
    </div>
  )
}

function RecruiterCardList() {
  const { recruiterResults, showViewMoreButton, amountShown, amountTotal } =
    useRecruiterSearch()

  return (
    <>
      <ul className="flex flex-col gap-4">
        {recruiterResults.map((recruiter, i) => (
          <RecruiterCard key={i} recruiter={recruiter} />
        ))}
      </ul>
      <div className="my-4 flex flex-col gap-4">
        <div className="text-center text-xs">
          Displaying {amountShown} of {amountTotal} recruiters
        </div>
        {showViewMoreButton && <ViewMoreButton />}
      </div>
    </>
  )
}
