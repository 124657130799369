import React, { useState } from 'react'
import { Button } from '../../lib/ui/button'
import { apiPatch } from '../../util/api'
import SetTimelineForm from './SetTimelineForm'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from '../../lib/ui/dialog'
import { store } from '../../lib/store'
import { setTimeline } from './ActionPlanSlice'
import { getUserTrackerSections } from './index'
import { TimeIcon } from './TrackTileComponents/icons'

const SetTimelineDialog = ({
  showDialog,
  toggleDialog,
  timeline,
  children,
}) => {
  const [newTimeline, setNewTimeline] = useState(timeline)

  const updateTimeline = async () => {
    const res = await apiPatch(`/trackers/set_timeline`, {
      tracker: { desired_timeline: newTimeline },
    })

    const data = res.data

    if (res.status === 200) {
      getUserTrackerSections()
      const timeLineText =
        data.trackerTimeline == 0 || data.trackerTimeline == null
          ? 'Set Date'
          : data.trackerTimeline +
            (data.trackerTimeline == 1 ? ' month' : ' months')

      store.dispatch(setTimeline(timeLineText))

      return res
    } else {
      throw new Error('res not ok')
    }
  }

  const handleSave = () => {
    toggleDialog()
    if (newTimeline) {
      updateTimeline()
    }
  }
  return (
    <Dialog open={showDialog} onOpenChange={toggleDialog}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Set Your Desired Timeline</DialogTitle>
          <TimeIcon className="h-6 w-6" variant="light" />
        </DialogHeader>
        <SetTimelineForm setNewTimeline={setNewTimeline} />
        <DialogFooter>
          <Button onClick={handleSave}>Save</Button>
        </DialogFooter>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
export default SetTimelineDialog
