import * as React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import useSavedRecruiters from './SavedRecruitersProvider'
import { apiPost } from '../../../util/api'

const useBulkSavedRecruitersForm = (setComplete) => {
  const { candidateId, toggleDialog } = useSavedRecruiters()
  const [submitted, setSubmitted] = React.useState(false)
  const schema = z.object({
    remote_content: z.instanceof(File),
  })

  const defaultValues = {}

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const onSubmit = async (values) => {
    setSubmitted(true)
    const url = `/job-search/recruiters/document/bulk_saved_recruiters`

    const data = {
      bulk_saved_recruiters: {
        name: 'Bulk Saved Recruiters',
        remote_content: values.remote_content,
        candidate_id: candidateId,
      },
    }

    let response
    try {
      response = await apiPost(url, data, true)
      console.log(response)
    } catch (error) {
      console.log(error)
      window.location.reload()
    }
    if (response.status === 200) {
      toggleDialog()
      setComplete(true)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else {
      window.location.reload()
    }
  }
  return { form, onSubmit, submitted }
}

export default useBulkSavedRecruitersForm
