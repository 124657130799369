export const lora = {
  family: 'Lora',
  fonts: [
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Lora-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Lora-Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  ],
}

export const inter = {
  family: 'Inter',
  fonts: [
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Inter_18pt-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Inter_18pt-Italic.ttf',
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Inter_18pt-Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Inter_18pt-SemiBold.ttf',
      fontStyle: 'normal',
      fontWeight: 'semibold',
    },
  ],
}
