import React, { useState } from 'react'
import { useResume } from '../ResumeProvider'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import ExpandyPanel from './ExpandyPanel'
import { ArrayFields, EditorTextField } from './common'

const EducationItem = ({
  index,
  activeIndex,
  setActiveIndex,
  education = {},
}) => {
  const [institution, setInstitution] = useState(education.institution || '')
  const [studyType, setStudyType] = useState(education.studyType || '')
  const [score, setScore] = useState(education.score || '')
  const [startDate, setStartDate] = useState(education.startDate || '')
  const [endDate, setEndDate] = useState(education.endDate || '')
  const [courses, setCourses] = useState(education.courses || [])

  const { resumeDocument, setResumeDocument } = useResume()

  const headingLabel =
    institution && studyType
      ? `${studyType} at ${institution}`
      : institution || 'New Education History'

  const updateEducationItem = (e) => {
    e.preventDefault()
    const updatedEndDate = [null, 'present', 'Present', ''].includes(endDate)
      ? undefined
      : endDate
    const updatedEducation = resumeDocument.education.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          institution,
          studyType,
          startDate,
          endDate: updatedEndDate,
          courses,
        }
      }
      return item
    })
    setResumeDocument({ ...resumeDocument, education: updatedEducation })
  }

  const toggleExpanded = () => {
    if (activeIndex == index) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(index)
    }
  }

  const deleteEducation = () => {
    const updatedEducation = resumeDocument.education.filter(
      (_, i) => i !== index,
    )
    setResumeDocument({ ...resumeDocument, education: updatedEducation })
  }

  const expanded = activeIndex == index

  const commonProps = {
    index,
    onBlur: updateEducationItem,
    section: 'education',
  }

  return (
    <div className="mb-4 flex flex-row items-center">
      <ExpandyPanel
        headingText={headingLabel}
        expanded={expanded}
        index={index}
        onClick={toggleExpanded}
      >
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorTextField
            label="School / College / University Name"
            value={institution}
            updateFunc={setInstitution}
            containerClassName="md:col-span-2"
            {...commonProps}
          />
          <EditorTextField
            label="Degree"
            value={studyType}
            updateFunc={setStudyType}
            {...commonProps}
          />
          <EditorTextField
            label="GPA"
            value={score}
            updateFunc={setScore}
            {...commonProps}
          />
          <EditorTextField
            label="Start Date"
            value={startDate}
            updateFunc={setStartDate}
            {...commonProps}
          />
          <EditorTextField
            label="End Date"
            value={endDate}
            updateFunc={setEndDate}
            {...commonProps}
          />
        </div>
        <div>
          <div className="flex flex-row gap-2 pt-4">
            <span className="font-bold">Coursework and Accomplishments</span>
            <button
              className="flex flex-row items-center text-primary"
              onClick={() => {
                setCourses([...courses, ''])
              }}
            >
              Add
              <PlusIcon className="ml-1 inline w-4 stroke-2" />
            </button>
          </div>
          <div className="flex flex-col gap-2 pt-4">
            <ArrayFields
              items={courses}
              keyPrefix="course"
              onChange={setCourses}
              onBlur={updateEducationItem}
            />
          </div>
        </div>
      </ExpandyPanel>
      {!expanded && (
        <button
          onClick={deleteEducation}
          className="max-w-min text-[#b9b9b9]"
          aria-label="remove education"
        >
          <TrashIcon className="ml-2 w-8" />
        </button>
      )}
    </div>
  )
}
export default EducationItem
