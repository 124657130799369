import React, { useState } from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { useExercise } from './ExerciseProvider'
import { AylaButton } from './Buttons'
import TypingEffect from '../stories/cover_letters/CreateAi/TypingEffect'

const ReasonForLeavingSectionTwo = () => {
  const {
    updateResponse,
    getResponse,
    formattedExerciseName,
    exerciseIndex,
    requestAylaStatement,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const craftYourResponse = t('pages.1.craft_your_response')
  const askAyla = t('pages.1.ask_ayla')
  const aylaInstructions = t('pages.1.ayla_instructions')

  const [aylaResponse, setAylaResponse] = useState('')
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const [typingComplete, setTypingComplete] = useState(false)
  const [statementType, setStatementType] = useState('')

  const handleChange = (section, slug, event) => {
    const response = event.target.value
    updateResponse(section, slug, response)
  }

  const handleAylaStatement = async (statementType) => {
    try {
      const data = await requestAylaStatement(statementType)
      setAylaResponse(data.response)
      setDisplayedText('')
      setIndex(0)
      setTypingComplete(false)
      setStatementType(statementType)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleUseThisStatement = () => {
    updateResponse(exerciseIndex, 'user_crafted_statement', aylaResponse)
  }

  return (
    <>
      <div>
        <h2 className="mb-4">{craftYourResponse}</h2>
        <textarea
          className="min-h-[175px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
          onChange={(e) =>
            handleChange(exerciseIndex, 'user_crafted_statement', e)
          }
          value={getResponse(exerciseIndex, 'user_crafted_statement') || ''}
        />
      </div>
      <div className="mt-8">
        <h2 className="mb-4">{askAyla}</h2>
        <p className="mb-4">{aylaInstructions}</p>
        <div className="flex gap-12">
          <AylaButton onClick={() => handleAylaStatement('LinkedIn')}>
            LinkedIn
          </AylaButton>
          <AylaButton
            onClick={() => handleAylaStatement('Professional Connections')}
          >
            Professional Connections
          </AylaButton>
        </div>
        {aylaResponse && (
          <div className="mt-4 min-h-[150px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base">
            <h3 className="mb-2">
              {statementType === 'LinkedIn'
                ? "Ayla's Response For LinkedIn Network:"
                : "Ayla's Response for Professional Connections:"}
            </h3>
            <TypingEffect
              message={aylaResponse}
              baseSpeed={5}
              aiStreamComplete={typingComplete}
              setTypingComplete={setTypingComplete}
              displayedText={displayedText}
              setDisplayedText={setDisplayedText}
              index={index}
              setIndex={setIndex}
            />
            {typingComplete && (
              <AylaButton onClick={handleUseThisStatement}>
                Use This Statement
              </AylaButton>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ReasonForLeavingSectionTwo
