import React, { createContext, useContext } from 'react'
import { apiDelete, apiGet, apiPost } from '../../../util/api'

const AlertsContext = createContext()

export const useAlerts = () => {
  return useContext(AlertsContext)
}

export const AlertsProvider = ({
  serialized_alerts,
  translations,
  children,
}) => {
  const alerts = serialized_alerts
  const emptyState = !alerts.length

  const deleteAlert = async (id) => {
    console.log('alert id delete', id)
    let response
    try {
      response = await apiDelete(`/job-search/alerts/${id}`)
    } catch (error) {
      console.log(error)
    }
    if (response.status !== 200) {
      throw new Error('Error deleting item')
    } else {
      window.location.reload()
    }
  }

  const fetchAlertJobCount = async (alert) => {
    const url = `/job-search/alerts/${alert.id}/job_count_from_id`
    let response
    try {
      response = await apiGet(url)
    } catch (error) {
      console.log(error)
    }
    if (response.status === 200) {
      return response
    } else throw new Error('Error fetching job count')
  }

  const createTransitionNotification = async (type = null) => {
    const url = '/transition-notification'
    const viewedResults = {
      activity_text: 'viewed results from a job alert',
      activity_points: 2,
      achieved: 'viewed_job_alert_results',
    }

    const editedJobAlert = {
      activity_text: 'edited a job alert',
      activity_points: 5,
      achieved: 'edited_job_alert',
    }

    const dataInfo = type === 'viewedResults' ? viewedResults : editedJobAlert
    const data = {
      notification_type: 'completed_activity',
      ...dataInfo,
      module: 'job_search',
    }
    let response
    try {
      response = await apiPost(url, data)
    } catch (error) {
      console.log(error)
    }
    if (response.status !== 200) {
      throw new Error('Error creating transition notification')
    } else {
      return response
    }
  }

  const value = {
    alerts,
    translations,
    emptyState,
    deleteAlert,
    fetchAlertJobCount,
    createTransitionNotification,
  }

  return (
    <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>
  )
}
