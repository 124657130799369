import React, { useState } from 'react'
import { useResume } from '../ResumeProvider'
import { Input } from '../../../lib/ui/input'
import { Label } from '../../../lib/ui/label'
import { EditorHeading } from './common'

const BasicsEditor = () => {
  const { resumeDocument, setResumeDocument } = useResume()

  const { basics } = resumeDocument

  const [name, setName] = useState(basics?.name || '')
  const [label, setLabel] = useState(basics?.label || '')
  const [phone, setPhone] = useState(basics?.phone || '')
  const [email, setEmail] = useState(basics?.email || '')
  const [url, setUrl] = useState(basics?.url || '')
  const [linkedinProfile, setLinkedinProfile] = useState(
    basics?.profiles?.find(
      (profile) => profile.network?.toLowerCase() === 'linkedin',
    ) || {
      network: 'LinkedIn',
      url: '',
    },
  )

  const saveBasics = () => {
    const profiles =
      basics?.profiles?.filter(
        (profile) => profile.network.toLowerCase() !== 'Linkedin',
      ) || []
    const updatedProfiles = [...profiles, linkedinProfile]
    const updatedBasics = {
      ...resumeDocument.basics,
      name,
      label,
      phone,
      email,
      url,
      profiles: updatedProfiles,
    }
    const updatedResume = {
      ...resumeDocument,
      basics: updatedBasics,
    }
    setResumeDocument(updatedResume)
  }

  return (
    <div>
      <EditorHeading>Personal Details</EditorHeading>
      <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
        <div className="md:col-span-2">
          <Label htmlFor="jobTitle">Job Title</Label>
          <Input
            onBlur={saveBasics}
            value={label}
            id="jobTitle"
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>
        <div className="md:col-span-2">
          <Label htmlFor="fullName">Full Name</Label>
          <Input
            onBlur={saveBasics}
            value={name}
            id="fullName"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="phone">Phone</Label>
          <Input
            onBlur={saveBasics}
            value={phone}
            id="phone"
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="email">Email</Label>
          <Input
            onBlur={saveBasics}
            value={email}
            id="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="website">Website</Label>
          <Input
            onBlur={saveBasics}
            value={url}
            id="website"
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="linkedin">LinkedIn</Label>
          <Input
            onBlur={saveBasics}
            value={linkedinProfile?.url}
            id="linkedin"
            onChange={(e) =>
              setLinkedinProfile({ ...linkedinProfile, url: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  )
}

export default BasicsEditor
