import * as React from 'react'

export function FrequencyCell({ row }) {
  const alert = row.original

  return (
    <div className="flex flex-col gap-1 pr-2">
      <div className="flex flex-row items-center gap-1 text-sm">
        <div className="flex flex-col">{alert.frequency}</div>
      </div>
    </div>
  )
}
