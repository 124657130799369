import React, { useState } from 'react'
import { useResume } from '../ResumeProvider'
import { Button } from '../../../lib/ui/button'
import { PlusIcon } from '@heroicons/react/24/outline'
import { EditorHeading, newKey } from './common'
import EmploymentItem from './EmploymentItem'

const EmploymentEditor = () => {
  const { resumeDocument, setResumeDocument } = useResume()
  const [activeIndex, setActiveIndex] = useState(-1)
  const work = resumeDocument?.work || []

  const addEmployment = () => {
    const newIndex = work?.length || 0
    const updatedWork = [...work, {}]

    setResumeDocument({ ...resumeDocument, work: updatedWork })
    setActiveIndex(newIndex)
  }

  return (
    <div className="flex flex-col">
      <EditorHeading>Employment History</EditorHeading>
      <div className="pb-4 tracking-[0.024px]">
        Show your relevant work experience, show accomplishments, and if
        possible, use numbers/facts.
      </div>
      {work.map((employment, index) => {
        employment.key = employment.key || newKey()
        return (
          <EmploymentItem
            index={index}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            key={employment.key}
            employment={employment}
          />
        )
      })}
      <Button onClick={addEmployment} className="max-w-min">
        Add Employment
        <PlusIcon className="ml-2 w-5" />
      </Button>
    </div>
  )
}
export default EmploymentEditor
