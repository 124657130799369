import React, { useEffect } from 'react'
import { useExercise } from './ExerciseProvider'
import { useTranslation } from '../../hooks/useTranslation'
import { StartExerciseButton } from './Buttons'
import { ClockIcon, ExerciseIcon, ConclusionPageImage } from './icons'
import { useMediaQuery } from 'react-responsive'

const StartExercise = ({ exerciseExists }) => {
  const { setStarted, saveExercise, formattedExerciseName, module } =
    useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const { t: tExercise } = useTranslation('exercise')
  const StartExercise = tExercise('start_exercise')
  const ContinueExercise = tExercise('continue_exercise')
  const buttonText = exerciseExists ? ContinueExercise : StartExercise
  const title = t('start_exercise.title')
  const description = t('start_exercise.description')
  const whyCompleteExercise = tExercise('why_complete_exercise')
  const wantToCompleteAsPdf = tExercise('want_to_complete_as_pdf')
  const download = tExercise('download')
  const pdf = tExercise('pdf')
  const emptyPdfLink = `/resource_export/${module}/${formattedExerciseName}?format=pdf`
  const relocateStartButton = useMediaQuery({ query: '(max-width: 1200px)' })
  const changeInfoLayout = useMediaQuery({ query: '(max-width: 975px)' })

  const handleStartExerciseClick = () => {
    setStarted(true)
    if (exerciseExists) {
      setStarted(true)
    } else {
      saveExercise()
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <div className="p-10">
      {/* Title Section */}
      <div>
        <h1 className="!mb-4 font-roboto-slab text-3xl font-bold text-gray-800">
          {title}
        </h1>
        <p className="!mb-4 !mt-0 text-lg font-medium text-gray-700">
          {whyCompleteExercise}
        </p>
      </div>

      {/* Description Section */}
      <div className="flex gap-4">
        <div className="mb-8 w-auto space-y-2 text-base text-gray-600">
          {description.map((desc, idx) => (
            <p key={`description-p-${idx}`}>{desc}</p>
          ))}
        </div>
        <div className="-mt-10 w-1/2">
          {!relocateStartButton && (
            <StartExerciseButton onClick={handleStartExerciseClick}>
              {buttonText} <ExerciseIcon className="h-10 w-10" />
            </StartExerciseButton>
          )}
        </div>
      </div>

      {relocateStartButton && (
        <div className="flex items-center justify-center">
          <StartExerciseButton
            className="!mt-0 mb-8"
            onClick={handleStartExerciseClick}
          >
            {buttonText} <ExerciseIcon className="h-8 w-8" />
          </StartExerciseButton>
        </div>
      )}

      {/* Info Cards */}
      {!changeInfoLayout && (
        <div className="flex flex-row justify-between rounded-[20px] bg-[#E0F2F9] p-5 text-primary">
          <div className="flex flex-col gap-4">
            <div className="font-medium">{wantToCompleteAsPdf}</div>
            <a
              href={emptyPdfLink}
              target="_blank"
              rel="noopener noreferrer"
              className="!underline"
            >
              {download}
              {title}
              {pdf}
            </a>
            <div className="mt-6 flex w-fit justify-start gap-8 rounded-[12px] border border-[#E5E5E5] bg-[#FFFFFF] px-6 py-4">
              <div className="flex items-center gap-3">
                <ClockIcon className="h-6 w-6 text-primary" />
                <span className="text-base font-medium">
                  {t('start_exercise.time')}
                </span>
              </div>
              <div className="flex items-center gap-3 border-l border-[#E5E5E5] pl-8">
                <ExerciseIcon className="h-10 w-10" source="StartExercise" />
                <span className="text-base font-medium">
                  {t('start_exercise.questions_total')}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2 pr-16">
            <ConclusionPageImage />
          </div>
        </div>
      )}

      {changeInfoLayout && (
        <div className="flex flex-col rounded-[20px] bg-[#E0F2F9] p-5 text-primary">
          <div className="flex flex-col gap-4">
            <div className="font-medium">{wantToCompleteAsPdf}</div>
            <a
              href={emptyPdfLink}
              target="_blank"
              rel="noopener noreferrer"
              className="!underline"
            >
              {download}
              {title}
              {pdf}
            </a>
            <div className="flex flex-col items-center justify-center">
              <div className="mb-4 flex w-fit justify-start gap-8 rounded-[12px] border border-[#E5E5E5] bg-[#FFFFFF] px-6 py-4">
                <div className="flex items-center gap-3">
                  <ClockIcon className="h-6 w-6 text-primary" />
                  <span className="text-base font-medium">
                    {t('start_exercise.time')}
                  </span>
                </div>
              </div>
              <div className="mb-2 flex w-fit justify-start gap-8 rounded-[12px] border border-[#E5E5E5] bg-[#FFFFFF] px-6 py-4">
                <div className="flex items-center gap-3">
                  <ExerciseIcon className="h-10 w-10" source="StartExercise" />
                  <span className="text-base font-medium">
                    {t('start_exercise.questions_total')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 flex justify-center">
            <ConclusionPageImage />
          </div>
        </div>
      )}
    </div>
  )
}

export default StartExercise
