import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RecruiterSearchProvider } from './RecruiterSearchProvider'
import translations from '../locales.json'
import { TranslationProvider } from '../../shared/TranslationProvider'
import ToolBar from './ToolBar'
import SearchResults from './SearchResults'
import { BrowserRouter } from 'react-router-dom'

const queryClient = new QueryClient()

export default function RecruiterSearchPage({ locale, ...props }) {
  return (
    <QueryClientProvider client={queryClient}>
      <TranslationProvider
        locale={locale}
        translations={translations}
        scope="javascript.components.job_search.RecruiterSearchPage"
      >
        <BrowserRouter>
          <RecruiterSearchProvider {...props}>
            <div className="flex flex-col border-l border-alto-200 pl-2 font-roboto">
              <div className="my-10 ml-4 mr-6 flex flex-col gap-8">
                <ToolBar />
                <SearchResults />
              </div>
            </div>
          </RecruiterSearchProvider>
        </BrowserRouter>
      </TranslationProvider>
    </QueryClientProvider>
  )
}
