import * as React from 'react'

import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { Button } from '../../../lib/ui/button'
import { cva } from 'class-variance-authority'

import { cn } from '../../../lib/utils'

export function CoachButton({
  children,
  className,
  variant,
  size,
  disabled,
  disabledText,
  ...props
}) {
  const buttonVariants = cva('', {
    variants: {
      variant: {
        default: 'bg-intoo-green-coachcta hover:bg-intoo-green-coachcta-hover',
        outline:
          'border border-cod-gray-700 bg-white text-foreground hover:bg-cod-gray-75 hover:text-accent-foreground',
        link: 'bg-white text-[#2F6D29] hover:bg-white underline-offset-4 hover:underline hover:text-link-hover ',

        ghost:
          'hover:bg-cod-gray-75 bg-white text-[#2F6D29] hover:text-intoo-green-coachcta underline decoration-[#2F6D29]',
      },
      size: {
        default: 'h-10 px-8 py-2',
        sm: 'h-9 px-3',
        lg: 'h-3 px-12 text-sm',
        xl: 'h-[3.75rem] px-16 text-xl',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  })

  return (
    <Button
      className={cn(buttonVariants({ variant, size, className }))}
      disabled={disabled}
      {...props}
    >
      {disabled ? (
        <>
          <CheckCircleIcon className="ml-0 mr-2 h-5 w-5 text-white" />
          {disabledText}
        </>
      ) : (
        children
      )}
    </Button>
  )
}
