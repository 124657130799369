import * as React from 'react'
import { cn } from '../../../lib/utils'

const Accolade = ({ className, children }) => {
  return <div className={cn(className, '')}>{children}</div>
}

const RatingTitle = ({ className, children }) => {
  return <div className={cn(className, '')}>{children}</div>
}

const AccoladeRating = ({ children, className }) => {
  return <div className={cn(className, '')}>{children}</div>
}

Accolade.RatingTitle = RatingTitle
Accolade.AccoladeRating = AccoladeRating
RatingTitle.displayName = 'RatingTitle'
AccoladeRating.displayName = 'AccoladeRating'

export const AccoladeMeetYourCoach = ({ className, rating, title }) => {
  return (
    <Accolade
      className={cn(
        'col-span-3 flex items-center justify-center rounded-[20px] bg-intoo-green-coachcta p-4 xl:col-span-1 xl:max-w-60',
        className,
      )}
    >
      <RatingTitle className="flex flex-wrap items-center justify-center gap-2 font-roboto-slab font-bold text-white">
        <AccoladeRating className="row-span-2 text-[32px] leading-[48px]">
          {rating}
        </AccoladeRating>
        <div className="col-span-2 text-lg leading-5 xl:max-w-20">{title}</div>
      </RatingTitle>
    </Accolade>
  )
}

export const AccoladeDashboard = ({
  rating,
  title,
  description,
  className,
}) => {
  return (
    <Accolade className={cn('flex flex-col gap-2 py-4', className)}>
      <RatingTitle className="flex items-center gap-3">
        <AccoladeRating
          className={
            'font-ibm bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright bg-clip-text font-ibm-plex text-5xl font-bold text-transparent'
          }
        >
          {rating}
        </AccoladeRating>
        <h2 className="w-2 p-0 text-lg font-bold italic leading-tight">
          {title}...
        </h2>
      </RatingTitle>
      <p className="line-clamp-4">{description}</p>
    </Accolade>
  )
}

export { Accolade, RatingTitle, AccoladeRating }
