import * as React from 'react'
import { Calendar } from '../../coach_services/OutplacementBenefits/Calendar'
import { ActionPlanCard } from '../cards/ActionPlanCard'
import { MyJobsCard } from '../cards/MyJobsCard'
import { CoachSupportCard } from '../cards/CoachSupportCard'
import { QuoteCard } from '../cards/QuoteCard'
import { RecommendedJobsCard } from '../cards/RecommendedJobsCard'
import { RecommendedBlogsCard } from '../cards/RecommendedBlogsCard'

export function Flex({
  tracker_time_period,
  applied_job_count,
  coach,
  quote,
  recommended_jobs,
  recommended_blogs,
  calendar_events,
  time_since_last_check_in,
}) {
  return (
    <>
      <div className="flex flex-col gap-[32px] md:grid md:grid-cols-7">
        <ActionPlanCard
          tracker_time_period={tracker_time_period}
          className="md:col-span-3"
        />
        <MyJobsCard
          applied_job_count={applied_job_count}
          className="md:col-span-2"
        />
        <CoachSupportCard
          coach_image={coach.image}
          time_since_last_check_in={time_since_last_check_in}
          className="md:col-span-2"
        />
      </div>
      <QuoteCard quote={quote} />
      <div className="flex flex-col gap-[32px] md:grid md:grid-cols-7">
        <RecommendedJobsCard
          recommended_jobs={recommended_jobs}
          className="md:col-span-3"
        />
        <RecommendedBlogsCard
          recommended_blogs={recommended_blogs}
          className="md:col-span-4"
        />
      </div>
      <div className="relative flex flex-col gap-[16px]">
        <div className="text-2xl font-bold text-primary">
          Upcoming Live Webinars
        </div>
        <div className="inline-block">
          Don't miss out! Check out our live webinar schedule now.
        </div>
        <Calendar calendar_events={calendar_events} homePageDisplay={true} />
      </div>
    </>
  )
}
