import React, { useState } from 'react'
import { useResume } from '../ResumeProvider'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import ExpandyPanel from './ExpandyPanel'
import { ArrayFields, EditorTextField } from './common'

const EmploymentItem = ({
  index,
  activeIndex,
  setActiveIndex,
  employment = {},
}) => {
  const [position, setPosition] = useState(employment.position || '')
  const [name, setName] = useState(employment.name || '')
  const [startDate, setStartDate] = useState(employment.startDate || '')
  const [endDate, setEndDate] = useState(employment.endDate || '')
  const [highlights, setHighlights] = useState(employment.highlights || [])

  const [headingLabel, setHeadingLabel] = useState(
    employment.position && employment.name
      ? `${employment.position} at ${employment.name}`
      : employment.position || employment.name || 'New Work History',
  )

  const { resumeDocument, setResumeDocument } = useResume()

  const updateEmploymentItem = () => {
    const newHeadingLabel =
      position && name
        ? `${position} at ${name}`
        : position || name || 'New Work History'
    setHeadingLabel(newHeadingLabel)

    const updatedEndDate = [null, 'present', 'Present', ''].includes(endDate)
      ? undefined
      : endDate
    const updatedWork = resumeDocument.work.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          position,
          name,
          startDate,
          endDate: updatedEndDate,
          highlights,
        }
      }
      return item
    })
    setResumeDocument({ ...resumeDocument, work: updatedWork })
  }

  const toggleExpanded = () => {
    if (activeIndex == index) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(index)
    }
  }

  const deleteEmployment = () => {
    const updatedWork = resumeDocument.work.filter((_, i) => i !== index)
    setResumeDocument({ ...resumeDocument, work: updatedWork })
  }

  const deleteHighlight = (highlightIndex) => {
    const updatedHighlights = highlights.filter((_, i) => i !== highlightIndex)
    setHighlights(updatedHighlights)

    const updatedWork = resumeDocument.work.map((item, i) => {
      if (i === index) {
        return {
          position,
          name,
          startDate,
          endDate,
          highlights: updatedHighlights,
        }
      }
      return item
    })

    setResumeDocument({ ...resumeDocument, work: updatedWork })
  }

  const expanded = activeIndex == index

  const commonProps = {
    index,
    onBlur: updateEmploymentItem,
    section: 'employment',
  }

  return (
    <div className="mb-4 flex flex-row items-center">
      <ExpandyPanel
        headingText={headingLabel}
        expanded={expanded}
        index={index}
        onClick={toggleExpanded}
        className="flex-grow"
      >
        <div
          id={`employment-${index}`}
          className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2"
        >
          <EditorTextField
            label="Position"
            value={position}
            updateFunc={setPosition}
            {...commonProps}
          />
          <EditorTextField
            label="Employer"
            value={name}
            updateFunc={setName}
            {...commonProps}
          />
          <EditorTextField
            label="Start Date"
            value={startDate}
            updateFunc={setStartDate}
            {...commonProps}
          />
          <EditorTextField
            label="End Date"
            value={endDate}
            updateFunc={setEndDate}
            {...commonProps}
          />
        </div>
        <div>
          <div className="flex flex-row gap-2 pt-4">
            <span className="font-bold">Highlights</span>
            <button
              className="flex flex-row items-center text-primary"
              onClick={() => {
                setHighlights([...highlights, ''])
              }}
            >
              Add
              <PlusIcon className="ml-1 inline w-4 stroke-2" />
            </button>
          </div>
          <div className="flex flex-col gap-2 pt-4">
            <ArrayFields
              items={highlights}
              keyPrefix="highlight"
              onChange={setHighlights}
              onBlur={updateEmploymentItem}
              onDeleteClick={deleteHighlight}
            />
          </div>
        </div>
      </ExpandyPanel>
      {!expanded && (
        <button
          onClick={deleteEmployment}
          className="max-w-min text-[#b9b9b9]"
          aria-label="remove employment"
        >
          <TrashIcon className="ml-2 w-8" />
        </button>
      )}
    </div>
  )
}
export default EmploymentItem
