import * as React from 'react'
import { useAlerts } from './AlertsProvider'
import useTranslation from '../../shared/TranslationProvider'
import { EditIcon } from './icons'

export function EditAlertCell({ row }) {
  const { createTransitionNotification } = useAlerts()
  const alert = row.original
  const { id } = alert
  const { t } = useTranslation('DataTable')

  return (
    <a
      onClick={() => createTransitionNotification()}
      href={`/job-search/alerts/${id}/edit`}
      title={t('edit_alert')}
    >
      <EditIcon />{' '}
    </a>
  )
}
