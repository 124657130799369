import React from 'react'
import { AlertsProvider } from './AlertsProvider'
import Page from './Page'
import translations from '../locales.json'
import { TranslationProvider } from '../../shared/TranslationProvider'

const AlertsPage = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.job_search.AlertsPage"
    >
      <AlertsProvider {...props}>
        <AlertsPageInner />
      </AlertsProvider>
    </TranslationProvider>
  )
}
export default AlertsPage

const AlertsPageInner = () => {
  return (
    <div>
      <div className="flex flex-col border-l border-alto-200">
        <Page />
      </div>
    </div>
  )
}
