import * as React from 'react'
import { cn } from '../../lib/utils'

function CoachHeader({ children, className }) {
  return (
    <div className="flex flex-col gap-4">
      <h1
        className={cn(
          'h1-meet-coach m-0 p-0 font-roboto-slab text-3.5xl leading-tight !text-intoo-green-coachcta',
          className,
        )}
      >
        {children}
      </h1>
    </div>
  )
}

const CoachHeaderBold = ({ children }) => {
  return <span className="font-bold">{children}</span>
}

CoachHeader.CoachHeaderBold = CoachHeaderBold
CoachHeaderBold.displayName = 'CoachHeaderBold'
export { CoachHeader, CoachHeaderBold }
