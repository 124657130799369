import React from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { useExercise } from './ExerciseProvider'
import parse from 'html-react-parser'
import { cn } from '../../lib/utils'
import { useMediaQuery } from 'react-responsive'

const TextAreaComponent = ({ placeholder, onChange, value, className }) => (
  <textarea
    className={cn(
      `className mx-4 max-h-[48px] resize-none overflow-hidden text-ellipsis whitespace-nowrap rounded-[12px] border border-[#D3D3D3] bg-[#F7FAFC] px-3 py-[.625rem] font-roboto text-base ${className}`,
    )}
    placeholder={placeholder}
    onChange={onChange}
    value={value}
  />
)

const JobSearchGoalComponent = ({
  handleChange,
  getResponse,
  exerciseIndex,
}) => {
  const { formattedExerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const changeLayout = useMediaQuery({ query: '(max-width: 1465px)' })

  return (
    <div className="font-roboto-light !mb-4 text-base font-normal">
      <style>
        {`
          .text-ellipsis::placeholder {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `}
      </style>
      {changeLayout ? (
        <>
          <div className="mb-2 flex items-center">
            <span>{t(`pages.2.position_title_description`)}</span>
            <TextAreaComponent
              placeholder={t(`pages.2.position_title`)}
              onChange={(e) => handleChange(exerciseIndex, 'position_title', e)}
              value={getResponse(exerciseIndex, 'position_title') || ''}
              className="mr-0 w-2/4"
            />
          </div>
          <div className="mb-2 flex items-center">
            <span>{t(`pages.2.company_description`)}</span>
            <TextAreaComponent
              placeholder={t(`pages.2.description_of_company_type`)}
              onChange={(e) => handleChange(exerciseIndex, 'company', e)}
              value={getResponse(exerciseIndex, 'company') || ''}
              className="mr-0 w-2/4 text-ellipsis"
            />
          </div>
          <div className="mb-2 flex items-center">
            <span>{t(`pages.2.time_description`)}</span>
            <TextAreaComponent
              placeholder={t(`pages.2.time_period`)}
              onChange={(e) => handleChange(exerciseIndex, 'time', e)}
              value={getResponse(exerciseIndex, 'time') || ''}
              className="mr-0 w-2/4"
            />
          </div>
        </>
      ) : (
        <>
          <div className="mb-2 inline-flex items-center">
            <span>{t(`pages.2.position_title_description`)}</span>
            <TextAreaComponent
              placeholder={t(`pages.2.position_title`)}
              onChange={(e) => handleChange(exerciseIndex, 'position_title', e)}
              value={getResponse(exerciseIndex, 'position_title') || ''}
              className={'w-[14.25rem]'}
            />
          </div>
          <div className="mb-2 inline-flex items-center">
            <span>{t(`pages.2.company_description`)}</span>
            <TextAreaComponent
              placeholder={t(`pages.2.description_of_company_type`)}
              onChange={(e) => handleChange(exerciseIndex, 'company', e)}
              value={getResponse(exerciseIndex, 'company') || ''}
              className="w-[21.25rem]"
            />
          </div>
          <div className="mb-2 inline-flex items-center">
            <span>{t(`pages.2.time_description`)}</span>
            <TextAreaComponent
              placeholder={t(`pages.2.time_period`)}
              onChange={(e) => handleChange(exerciseIndex, 'time', e)}
              value={getResponse(exerciseIndex, 'time') || ''}
              className="mr-0 w-[14.25rem]"
            />
          </div>
        </>
      )}
    </div>
  )
}

const CareerBrainstormExercise1 = ({
  questions,
  handleChange,
  getResponse,
  exerciseIndex,
  section,
}) => {
  return (
    <div>
      {section === 0 && (
        <div className="questions">
          {questions.map((question, index) => (
            <div
              key={index}
              className="mb-6 w-full font-roboto text-base font-normal"
            >
              <div className="flex w-full flex-col">
                <div className="font-roboto-light !mb-4 text-base font-normal">
                  {parse(question.question)}
                </div>
                <div className="font-roboto-light !mb-1 text-base font-medium underline">
                  {question.text_label}
                </div>
                <textarea
                  className="min-h-[125px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
                  onChange={(e) =>
                    handleChange(exerciseIndex, question.slug, e)
                  }
                  value={getResponse(exerciseIndex, question.slug) || ''}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const CareerBrainstormExercise2 = ({
  questions,
  handleChange,
  getResponse,
  exerciseIndex,
  section,
}) => {
  const { formattedExerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  return (
    <div>
      {section === 1 && (
        <div className="questions">
          {questions.map((question, index) => (
            <div
              key={index}
              className="mb-6 w-full font-roboto text-base font-normal"
            >
              <div className="flex w-full flex-col">
                <div className="font-roboto-light !mb-4 text-base font-normal">
                  {parse(question.question)}
                </div>
                <div className="font-roboto-light !mb-1 text-base font-medium underline">
                  {question.text_label}
                </div>
                <textarea
                  className="min-h-[125px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
                  onChange={(e) =>
                    handleChange(exerciseIndex, question.slug, e)
                  }
                  value={getResponse(exerciseIndex, question.slug) || ''}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="font-roboto-light !mb-4 text-base font-normal">
        {parse(t(`pages.1.do_some_research`))}
      </div>
      <div className="font-roboto-light !mb-4 text-base font-normal">
        {parse(t(`pages.1.try_it_on`))}
      </div>
    </div>
  )
}

const CareerBrainstormExercise3 = ({
  questions,
  handleChange,
  getResponse,
  exerciseIndex,
  section,
}) => {
  const { formattedExerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  return (
    <div>
      {section === 2 && (
        <>
          <div className="font-roboto-light !mb-4 text-base font-normal">
            {parse(t(`pages.2.set_smart_goals`))}
          </div>
          <div className="font-roboto-light !mb-4 text-base font-medium underline">
            {t(`pages.2.job_search_goal`)}
          </div>
          <JobSearchGoalComponent
            handleChange={handleChange}
            getResponse={getResponse}
            exerciseIndex={exerciseIndex}
          />
          <div className="questions">
            <div className="mb-6 w-full font-roboto text-base font-normal">
              <div className="flex w-full flex-col">
                <div className="font-roboto-light !mb-4 text-base font-normal">
                  {parse(questions[0].question)}
                </div>
                <div className="font-roboto-light !mb-1 text-base font-medium underline">
                  {questions[0].text_label}
                </div>
                <textarea
                  className="min-h-[125px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
                  onChange={(e) =>
                    handleChange(exerciseIndex, questions[0].slug, e)
                  }
                  value={getResponse(exerciseIndex, questions[0].slug) || ''}
                />
              </div>
            </div>
            <div className="font-roboto-light !mb-4 text-base font-medium">
              {parse(t(`pages.2.build_your_network`))}
            </div>
            <div className="mb-6 w-full font-roboto text-base font-normal">
              <div className="flex w-full flex-col">
                <div className="font-roboto-light !mb-4 text-base font-normal">
                  {parse(questions[1].question)}
                </div>
                <div className="font-roboto-light !mb-1 text-base font-medium underline">
                  {questions[1].text_label}
                </div>
                <textarea
                  className="min-h-[125px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
                  onChange={(e) =>
                    handleChange(exerciseIndex, questions[1].slug, e)
                  }
                  value={getResponse(exerciseIndex, questions[1].slug) || ''}
                />
              </div>
            </div>
            <div className="font-roboto-light !mb-4 text-base font-medium">
              {parse(t(`pages.2.taking_action`))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const CareerBrainstormExercise4 = ({ section }) => {
  const { formattedExerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)

  const keys = ['update_your_resume', 'apply_for_jobs', 'practice_interviews']

  return (
    <div>
      {section === 3 && (
        <>
          {keys.map((key) => (
            <div
              key={key}
              className="font-roboto-light !mb-4 text-base font-normal"
            >
              {parse(t(`pages.3.${key}`))}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

const CareerBrainstormExercise = (props) => {
  const { section } = props
  if (section === 0) {
    return <CareerBrainstormExercise1 {...props} />
  } else if (section === 1) {
    return <CareerBrainstormExercise2 {...props} />
  } else if (section === 2) {
    return <CareerBrainstormExercise3 {...props} />
  } else if (section === 3) {
    return <CareerBrainstormExercise4 {...props} />
  } else {
    return null
  }
}

export default CareerBrainstormExercise
