import React, { useCallback, useEffect, useState } from 'react'
import { NavBubble } from './icons'

export const useDotButton = (emblaApi) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const onDotButtonClick = useCallback(
    (index) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi],
  )

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  }
}

export const DotButton = (props) => {
  const { children, ...restProps } = props

  const [active, setActive] = useState(false)
  const ariaLabel = restProps.ariaLabel

  useEffect(() => {
    if (restProps.className === 'embla__dot embla__dot--selected') {
      setActive(true)
    } else {
      setActive(false)
    }
  })

  return (
    <button type="button" aria-label={ariaLabel} {...restProps}>
      {children}
      <NavBubble active={active}></NavBubble>
    </button>
  )
}
