import React from 'react'
import { ScriptsIcon } from './icons'

const ScriptCard = ({ script }) => {
  const { headerImageAlt, path, title } = script

  return (
    <div className="h-[112px]">
      <a
        href={path}
        target="_blank"
        rel="noreferrer"
        className="flex h-full items-center rounded-[20px] border border-[#B9B9B9] px-4 py-2 hover:!underline"
      >
        <img
          src="https://www-yns-com.s3.us-east-2.amazonaws.com/images/job_search/scripts_resource_center.jpeg"
          alt={headerImageAlt}
          className="h-full rounded-[20px]"
        />
        <div className="ml-4 flex h-full w-full flex-col justify-center">
          <ScriptsIcon className="h-8 w-8" />
          <p>{title}</p>
        </div>
      </a>
    </div>
  )
}

export default ScriptCard
