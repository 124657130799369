import * as React from 'react'

export function CriteriaCell({ row }) {
  const alert = row.original
  const { name, created_time_ago } = alert

  const subTitle = <div className="line-clamp-1">{created_time_ago}</div>

  return (
    <div className="flex flex-col gap-1 pr-2">
      <div className="text-normal line-clamp-1 font-bold">{name}</div>
      <div className="flex flex-row items-center gap-1 text-sm">
        <div className="flex flex-col">{subTitle}</div>
      </div>
    </div>
  )
}
