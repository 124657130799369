import React from 'react'
import AssessmentCard from '../../AssessmentCenter/AssessmentCard'
import { useSkills } from '../SkillsProvider'
import { Separator } from '../../../lib/ui/separator'

const SkillsAssessments = () => {
  const { assessments } = useSkills()
  return (
    <>
      <Separator className="mt-8" />
      <div className="flex flex-col gap-8 px-4 pb-4 pt-8 md:px-0">
        <div>
          <h2 className="font-roboto-slab">Skills Assessments</h2>
          <p>
            Need help exploring your career path? Use our personal assessments
            to help you identify your values, personality, skills, and talents.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-x-12 gap-y-6">
          {assessments.map((assessment, idx) => (
            <AssessmentCard
              key={`assessment-${idx}`}
              assessment={assessment}
              className={'col-span-2 md:col-span-1'}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default SkillsAssessments
