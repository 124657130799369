import React, { useEffect, useState } from 'react'

import useSavedRecruiters from './SavedRecruitersProvider'
import { columns } from './columns'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../lib/ui/table'
import { useTranslation } from '../../../hooks/useTranslation'
import ExpandedCell from './ExpandedCell'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { cn } from '../../../lib/utils'
import { Button } from '../../../lib/ui/button'
import BulkUploadForm from './BulkUploadForm'
import { Input } from '../../../lib/ui/input'
import { Label } from '../../../lib/ui/label'
import { TrashIcon } from './Icons'

export function DataTable() {
  const { formattedQuizName } = useSavedRecruiters()
  const { t } = useTranslation(formattedQuizName)
  const { recruiterList } = useSavedRecruiters()
  const [expanded, setExpanded] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    setExpanded({})
  }, [recruiterList])

  const table = useReactTable({
    debugTable: true,
    data: recruiterList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: (row) => true,
    state: {
      expanded,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'includesString',
    enableMultiSorting: true,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
  })

  const [lastUpdatedId, setLastUpdatedId] = useState()

  const handleRowClick = (row) => () => {
    setLastUpdatedId(row.id)
  }

  const handleClearSearch = () => {
    setSearchInput('')
    table.setGlobalFilter('')
  }

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="flex-grow">
          <div className="flex flex-col">
            <div className="flex items-end gap-2">
              <div className="space-y-2">
                <Label htmlFor="recruiter_or_firm_name_search">
                  {t('Search.recruiter_or_firm_name')}
                </Label>
                <Input
                  id="recruiter_or_firm_name_search"
                  className={'md:w-96'}
                  onChange={(e) => setSearchInput(e.target.value)}
                  value={searchInput}
                  type="text"
                />
              </div>
              <Button onClick={() => table.setGlobalFilter(searchInput)}>
                Search
              </Button>
            </div>
            <Button
              className="self-start p-0"
              variant="link"
              onClick={handleClearSearch}
            >
              Clear Search
            </Button>
          </div>
        </div>
        <BulkUploadForm />
      </div>
      <div className="rounded-2xl border border-alto-200">
        <Table tableClass="rounded-2xl">
          <TableHeader className="rounded-2xl bg-intoo-blue-medium">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={`${headerGroup.id}-header_group`}
                className="border-alto-200 bg-intoo-blue-medium p-4 text-white  hover:bg-intoo-blue-medium sm:px-8 sm:py-6"
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={`${header.id}-header`}
                      className="job-search-table-header h-6 pl-0 text-sm font-bold text-foreground data-[col-id=status]:w-[96px] sm:h-12 sm:px-4 data-[col-id=status]:sm:w-[120px]"
                      data-col-id={header.column.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  )
                })}
                <TableHead className="job-search-table-header h-6 pl-0 text-sm font-bold text-foreground data-[col-id=status]:w-[96px] sm:h-12 sm:px-4 data-[col-id=status]:sm:w-[120px]">
                  {null}
                </TableHead>
                <TableHead className="job-search-table-header h-6 pl-0 text-sm font-bold text-foreground data-[col-id=status]:w-[96px] sm:h-12 sm:px-4 data-[col-id=status]:sm:w-[120px]">
                  {null}
                </TableHead>
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table
                .getRowModel()
                .rows.map((row) => (
                  <DataTableRow
                    key={`${row.id}-table_row`}
                    row={row}
                    lastUpdatedId={lastUpdatedId}
                    onClick={handleRowClick(row)}
                  />
                ))
            ) : (
              <TableRow className="p-4 sm:px-8 sm:py-6">
                <TableCell colSpan={columns.length + 2} className="h-24">
                  <div className="flex flex-col items-center justify-center">
                    <div className="max-w-96 text-center">
                      <h2 className="p-8">
                        {t('DataTable.no_saved_recruiters_title')}
                      </h2>
                      <p className="text-lg">
                        {t('DataTable.no_saved_jobs_message')}
                      </p>
                      <p className="text-lg">
                        <a href="/job-search/jobs">
                          {t('DataTable.explore_jobs')}
                        </a>
                      </p>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

const DataTableRow = ({ row, lastUpdatedId, ...props }) => {
  const { deleteRecruiter } = useSavedRecruiters()
  const { t } = useTranslation()

  const handleDeleteItem = async (e) => {
    e.preventDefault()
    window.confirm(t('FormActions.delete_confirm')) && deleteRecruiter(row)
  }

  return (
    <>
      <TableRow
        data-state={row.getIsSelected() && 'selected'}
        fred={5}
        className={
          ('border-alto-200 hover:bg-transparent data-[last-updated=true]:bg-alto-50',
          row.getIsExpanded() && 'border-b-0')
        }
        data-last-updated={row.id === lastUpdatedId}
        expanded={`${row.getIsExpanded()}`}
        {...props}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell
            key={`${row.id}_${cell.id}`}
            className="pb-2 pl-0 pr-0 pt-3 sm:p-4 sm:pr-1"
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
        {row.getCanExpand() && (
          <TableCell>
            <Button
              className="w-40"
              {...{ onClick: row.getToggleExpandedHandler() }}
            >
              {row.getIsExpanded() ? 'Hide Details' : 'See Details'}
              <ChevronDownIcon
                className={cn(
                  'ml-auto inline-block h-4 w-4 stroke-2 transition-all duration-500',
                  row.getIsExpanded() && 'rotate-180',
                )}
              />
            </Button>
          </TableCell>
        )}
        <TableCell>
          <Button
            onClick={handleDeleteItem}
            className="bg-transparent hover:bg-transparent"
            title={t('FormActions.delete')}
          >
            <TrashIcon
              className={
                'bg-transparent text-red-500 hover:bg-transparent hover:text-red-400'
              }
            />
          </Button>
        </TableCell>
      </TableRow>
      <TableRow className={cn(row.getIsExpanded() ? '' : 'border-b-0 p-0')}>
        <TableCell
          colSpan={columns.length + 2}
          className={cn('p-0', row.getIsExpanded() ? '' : 'border-b-0')}
        >
          <div
            className={cn(
              'bg-[#f7f7f7] transition-all duration-500',
              row.getIsExpanded()
                ? 'visible max-h-screen overflow-scroll md:overflow-hidden'
                : 'invisible max-h-0 overflow-hidden',
            )}
          >
            <ExpandedCell row={row} />
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}
