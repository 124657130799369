import React, { useState, createContext, useContext, useEffect } from 'react'
import { cn } from '../../lib/utils'

const TabNavContext = createContext()

export const useTabNav = () => {
  return useContext(TabNavContext)
}

export const TabPanel = ({ name, children }) => {
  const { activeTab } = useTabNav()
  if (activeTab != name) {
    return null
  }
  return <div className="h-full">{children}</div>
}

export const PanelContainer = ({ className, children }) => {
  return (
    <div className={cn('h-full w-full flex-grow', className)}>{children}</div>
  )
}

export const TabNav = ({ className, defaultActiveTab, children }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab)
  const tabButtonClick = (e) => {
    setActiveTab(e.target.closest('button').getAttribute('name'))
  }
  const providerValue = { activeTab, setActiveTab, tabButtonClick }
  return (
    <TabNavContext.Provider value={providerValue}>
      <div className={cn('flex h-full w-full flex-col', className)}>
        {children}
      </div>
    </TabNavContext.Provider>
  )
}

const ButtonStyleContext = createContext()

export const useButtonStyle = () => {
  return useContext(ButtonStyleContext)
}

export const ButtonContainer = ({
  className,
  children,
  buttonClass,
  titleClass,
  activeTitleClass,
  iconClass,
  activeDecorationClass,
}) => {
  const value = {
    buttonClass,
    titleClass,
    activeTitleClass,
    iconClass,
    activeDecorationClass,
  }
  return (
    <div
      className={cn(
        'flex w-full flex-row justify-evenly border-b border-solid border-[#979797] text-[#2F2F2F]',
        className,
      )}
    >
      <ButtonStyleContext.Provider value={value}>
        {children}
      </ButtonStyleContext.Provider>
    </div>
  )
}

export const TabButton = ({
  Icon,
  name,
  text,
  buttonClass,
  titleClass,
  activeTitleClass,
  iconClass,
  activeDecorationClass,
}) => {
  const { activeTab, tabButtonClick } = useTabNav()
  const style = useButtonStyle()

  const active = activeTab === name
  const decorationClass = active
    ? cn(
        'w-full rounded-t-sm h-1 bg-primary',
        style.activeDecorationClass,
        activeDecorationClass,
      )
    : 'h-1'
  const defaultTitleClass = cn(
    'my-2 whitespace-nowrap text-center text-lg',
    style.titleClass,
    titleClass,
  )
  const titleDisplayClass = active
    ? cn(
        'font-medium',
        defaultTitleClass,
        style.activeTitleClass,
        activeTitleClass,
      )
    : defaultTitleClass

  return (
    <button
      className={cn(
        'flex min-w-32 flex-col items-center',
        style.buttonClass,
        buttonClass,
      )}
      onClick={tabButtonClick}
      name={name}
    >
      <Icon className={cn('w-[2rem]', style.iconClass, iconClass)} />
      <div className={titleDisplayClass}>{text}</div>
      <div className={decorationClass}></div>
    </button>
  )
}
